<template>
  <div class="d-flex flex-wrap mt-2 container">
    <!-- <div class="container mb-2">
      <v-divider class="mx-4" color="black"></v-divider>
      <h1 class="text-center display-3 font-weight-light mt-2 mb-2">
        Neue Gerichte
      </h1>
      <v-divider class="mx-4 mb-6" color="black"></v-divider>
      <backtohome></backtohome>
    </div> -->
    <v-row>
            <v-col cols="12" sm="6" md="4">
        <v-card
          class="mx-auto"
          max-width="344"
          style="background-color: #212121;"
        >
          <v-carousel
            hide-delimiters
            cycle
            max-height="20%"
            background-size="cover"
          >
            <v-carousel-item
              v-for="(item, i) in kirinsushi"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
          <!-- <v-img src="../assets/images/tempuracaliforniacutted.png" height="200px"></v-img> -->
          <v-alert
            dense
            max-width="50%"
            color="green"
            class="text-center mx-auto mt-4"
          >
            NEUHEIT
          </v-alert>
          <v-card-title
            class="justify-center white--text font-weight-light"
            style="background-color: #212121; padding-top:0;"
          >
            Kirin Sushi
          </v-card-title>

          <v-card-subtitle
            class="text-center white--text font-weight-light"
            style="background-color: #212121;"
          >
            (8 Stk.)<br />
            13,90 €
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card
          class="mx-auto"
          max-width="344"
          style="background-color: #212121;"
        >
          <v-carousel
            hide-delimiters
            cycle
            max-height="20%"
            background-size="cover"
          >
            <v-carousel-item
              v-for="(item, i) in sushitatar"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
          <!-- <v-img src="../assets/images/tempuracaliforniacutted.png" height="200px"></v-img> -->
          <v-alert
            dense
            max-width="50%"
            color="green"
            class="text-center mx-auto mt-4"
          >
            NEUHEIT
          </v-alert>
          <v-card-title
            class="justify-center white--text font-weight-light"
            style="background-color: #212121; padding-top:0;"
          >
            Sushi Tatar
          </v-card-title>

          <v-card-subtitle
            class="text-center white--text font-weight-light"
            style="background-color: #212121;"
          >
            (3 Stk.)<br />
            13,90 €
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card
          class="mx-auto"
          max-width="344"
          style="background-color: #212121;"
        >
          <v-carousel
            hide-delimiters
            cycle
            max-height="20%"
            background-size="cover"
          >
            <v-carousel-item
              v-for="(item, i) in goldenmix"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
          <v-alert
            dense
            max-width="50%"
            color="green"
            class="text-center mx-auto mt-4"
          >
            NEUHEIT
          </v-alert>
          <v-card-title
            class="justify-center white--text font-weight-light"
            style="background-color: #212121; padding:0;"
          >
            Golden Mix
          </v-card-title>
          <v-card-subtitle
            class="text-center white--text font-weight-light mt-1"
            style="background-color: #212121;"
          >
            (31 Stk.)<br />
            59,90 €
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card
          class="mx-auto"
          max-width="344"
          style="background-color: #212121;"
        >
          <v-carousel
            hide-delimiters
            cycle
            max-height="20%"
            background-size="cover"
          >
            <v-carousel-item
              v-for="(item, i) in tatargurkenroll"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
          <v-alert
            dense
            max-width="50%"
            color="green"
            class="text-center mx-auto mt-4"
          >
            NEUHEIT
          </v-alert>
          <v-card-title
            class="justify-center white--text font-weight-light"
            style="background-color: #212121; padding:0;"
          >
            Tatar Gurken Roll
          </v-card-title>
          <v-card-subtitle
            class="text-center white--text font-weight-light mt-1"
            style="background-color: #212121;"
          >
            (9 Stk.)<br />
            13,50 €
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card
          class="mx-auto"
          max-width="344"
          style="background-color: #212121;"
        >
          <v-carousel
            hide-delimiters
            cycle
            max-height="20%"
            background-size="cover"
          >
            <v-carousel-item
              v-for="(item, i) in lachskaviarsushi"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
          <v-alert
            dense
            max-width="50%"
            color="green"
            class="text-center mx-auto mt-4"
          >
            NEUHEIT
          </v-alert>
          <v-card-title
            class="justify-center white--text font-weight-light"
            style="background-color: #212121; padding:0;"
          >
            Lachs Kaviar Sushi
          </v-card-title>
          <v-card-subtitle
            class="text-center white--text font-weight-light mt-1"
            style="background-color: #212121;"
          >
            (10 Stk.)<br />
            13,40 €
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card
          class="mx-auto"
          max-width="344"
          style="background-color: #212121;"
        >
          <v-carousel
            hide-delimiters
            cycle
            max-height="20%"
            background-size="cover"
          >
            <v-carousel-item
              v-for="(item, i) in flambiertesthunfischkaviarsushi"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
          <v-alert
            dense
            max-width="50%"
            color="green"
            class="text-center mx-auto mt-4"
          >
            NEUHEIT
          </v-alert>
          <v-card-title
            class="justify-center white--text font-weight-light"
            style="background-color: #212121; padding:0;"
          >
            Flambiertes Thunfisch Kaviar Sushi
          </v-card-title>
          <v-card-subtitle
            class="text-center white--text font-weight-light mt-1"
            style="background-color: #212121;"
          >
            (10 Stk.)<br />
            14,90 €
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card
          class="mx-auto"
          max-width="344"
          style="background-color: #212121;"
        >
          <v-carousel
            hide-delimiters
            cycle
            max-height="20%"
            background-size="cover"
          >
            <v-carousel-item
              v-for="(item, i) in flambierteslachskaviarsushi"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
          <v-alert
            dense
            max-width="50%"
            color="green"
            class="text-center mx-auto mt-4"
          >
            NEUHEIT
          </v-alert>
          <v-card-title
            class="justify-center white--text font-weight-light"
            style="background-color: #212121; padding:0;"
          >
            Flambiertes Lachs Kaviar Sushi
          </v-card-title>
          <v-card-subtitle
            class="text-center white--text font-weight-light mt-1"
            style="background-color: #212121;"
          >
            (10 Stk.)<br />
            13,90 €
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card
          class="mx-auto"
          max-width="344"
          style="background-color: #212121;"
        >
          <v-carousel
            hide-delimiters
            cycle
            max-height="20%"
            background-size="cover"
          >
            <v-carousel-item
              v-for="(item, i) in sushitorte"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
          <v-alert
            dense
            max-width="50%"
            color="green"
            class="text-center mx-auto mt-4"
          >
            NEUHEIT
          </v-alert>
          <v-card-title
            class="justify-center white--text font-weight-light"
            style="background-color: #212121; padding:0;"
          >
            Sushi Torte
          </v-card-title>
          <v-card-subtitle
            class="text-center white--text font-weight-light mt-1"
            style="background-color: #212121;"
          >
            (1 Stk.)<br />
            13,50 €
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card
          class="mx-auto"
          max-width="344"
          style="background-color: #212121;"
        >
          <v-carousel
            hide-delimiters
            cycle
            max-height="20%"
            background-size="cover"
          >
            <v-carousel-item
              v-for="(item, i) in sushidonuts"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
          <v-alert
            dense
            max-width="50%"
            color="green"
            class="text-center mx-auto mt-4"
          >
            NEUHEIT
          </v-alert>
          <v-card-title
            class="justify-center white--text font-weight-light"
            style="background-color: #212121; padding:0;"
          >
            Sushi Donuts
          </v-card-title>
          <v-card-subtitle
            class="text-center white--text font-weight-light mt-1"
            style="background-color: #212121;"
          >
            (3 Stk.)<br />
            13,50 €
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card
          class="mx-auto"
          max-width="344"
          style="background-color: #212121;"
        >
          <v-carousel
            hide-delimiters
            cycle
            max-height="20%"
            background-size="cover"
          >
            <v-carousel-item
              v-for="(item, i) in drachenmaki"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
          <v-alert
            dense
            max-width="50%"
            color="green"
            class="text-center mx-auto mt-4"
          >
            NEUHEIT
          </v-alert>
          <v-card-title
            class="justify-center white--text font-weight-light"
            style="background-color: #212121; padding:0;"
          >
            Drachen Maki
          </v-card-title>
          <v-card-subtitle
            class="text-center white--text font-weight-light mt-1"
            style="background-color: #212121;"
          >
            (8 Stk.)<br />
            14,90 €
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card
          class="mx-auto"
          max-width="344"
          style="background-color: #212121;"
        >
          <v-carousel
            hide-delimiters
            cycle
            max-height="20%"
            background-size="cover"
          >
            <v-carousel-item
              v-for="(item, i) in items"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
          <!-- <v-img src="../assets/images/avocadolachskugelcutted.png" height="200px"></v-img> -->
          <v-alert
            dense
            max-width="50%"
            color="green"
            class="text-center mx-auto mt-4"
          >
            NEUHEIT
          </v-alert>
          <v-card-title
            class="justify-center white--text font-weight-light"
            style="background-color: #212121; padding-top:0;"
          >
            Avocado Lachs Kugeln
          </v-card-title>

          <v-card-subtitle
            class="text-center white--text font-weight-light"
            style="background-color: #212121;"
          >
            (3 Stk.)<br />
            15,90 €
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card
          class="mx-auto"
          max-width="344"
          style="background-color: #212121;"
        >
          <v-carousel
            hide-delimiters
            cycle
            max-height="20%"
            background-size="cover"
          >
            <v-carousel-item
              v-for="(item, i) in lachsrollen"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
          <!-- <v-img src="../assets/images/lachsrollencutted.png" height="200px"></v-img> -->
          <v-alert
            dense
            max-width="50%"
            color="green"
            class="text-center mx-auto mt-4"
          >
            NEUHEIT
          </v-alert>
          <v-card-title
            class="justify-center white--text font-weight-light"
            style="background-color: #212121; padding-top:0;"
          >
            Lachs Sushi Rollen
          </v-card-title>

          <v-card-subtitle
            class="text-center white--text font-weight-light"
            style="background-color: #212121;"
          >
            (10 Stk.)<br />
            13,80 €
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card
          class="mx-auto"
          max-width="344"
          style="background-color: #212121;"
        >
          <v-carousel
            hide-delimiters
            cycle
            max-height="20%"
            background-size="cover"
          >
            <v-carousel-item
              v-for="(item, i) in tempuracalifornia"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
          <!-- <v-img src="../assets/images/tempuracaliforniacutted.png" height="200px"></v-img> -->
          <v-alert
            dense
            max-width="50%"
            color="green"
            class="text-center mx-auto mt-4"
          >
            NEUHEIT
          </v-alert>
          <v-card-title
            class="justify-center white--text font-weight-light"
            style="background-color: #212121; padding-top:0;"
          >
            Tempura California Maki
          </v-card-title>

          <v-card-subtitle
            class="text-center white--text font-weight-light"
            style="background-color: #212121;"
          >
            (12 Stk.)<br />
            11,90 €
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import Backtohome from './Backtohome.vue';
export default {
  // components: { Backtohome },
  data() {
    return {
                sushitatar: [
                {
          src: require('../assets/images/sushitataroverview.jpg'),
        },
        {
          src: require('../assets/images/sushitataroverviewwide.jpg'),
        },
        {
          src: require('../assets/images/sushitatarmicrooverviewthunfisch.jpg'),
        },
                {
          src: require('../assets/images/sushitatarmircooverviewlachs.jpg'),
        },
                        {
          src: require('../assets/images/sushitatarmicrooverviewgarnelen.jpg'),
        },
      ],
          kirinsushi: [
                {
          src: require('../assets/images/kirinsushifrontview.jpg'),
        },
        {
          src: require('../assets/images/kirinsushimicroview.jpg'),
        },
        {
          src: require('../assets/images/kirinsushiovermicroview.jpg'),
        },
                {
          src: require('../assets/images/kirinsushifrontmicroview.jpg'),
        },
                        {
          src: require('../assets/images/kirinsushioverview.jpg'),
        },
      ],
      goldenmix: [
                {
          src: require('../assets/images/Golden-schwarzeBackground-ganz.jpg'),
        },
        {
          src: require('../assets/images/Golden-CloseUp.jpg'),
        },
        {
          src: require('../assets/images/Golden-Mix-Frontal.jpg'),
        },
                {
          src: require('../assets/images/Golden-Mix-Oben.jpg'),
        },
      ],
      tatargurkenroll: [
        {
          src: require('../assets/images/tatargurkenrollseitlich.png'),
        },
        {
          src: require('../assets/images/tatargurkenrollvogel.png'),
        },
        {
          src: require('../assets/images/tatargurkenrollfront.png'),
        },
      ],
      lachskaviarsushi: [
        {
          src: require('../assets/images/lachskaviarsushifrontal.png'),
        },
        {
          src: require('../assets/images/lachskaviarsushioben.png'),
        },
      ],
      flambiertesthunfischkaviarsushi: [
        {
          src: require('../assets/images/thunfischkaviarsushiflambiertseitlich.png'),
        },
        {
          src: require('../assets/images/thunfischkaviarsushiflambiertfrontal.png'),
        },
      ],
      flambierteslachskaviarsushi: [
        {
          src: require('../assets/images/lachskaviarsushiflambiertoben.png'),
        },
        {
          src: require('../assets/images/lachskaviarsushiflambiertfrontal.png'),
        },
      ],
      sushitorte: [
        {
          src: require('../assets/images/sushitortefrontal.png'),
        },
        {
          src: require('../assets/images/sushitorteoben.png'),
        },
      ],
      sushidonuts: [
        {
          src: require('../assets/images/sushidonutsfrontal.png'),
        },
        {
          src: require('../assets/images/sushidonutsoben.png'),
        },
      ],
      drachenmaki: [
        {
          src: require('../assets/images/drachenmakifrontalweiss.jpg'),
        },
        {
          src: require('../assets/images/drachenmakivogelperspektive.jpg'),
        },

        {
          src: require('../assets/images/drachenmakiganzweiss.jpg'),
        },
      ],
      items: [
        {
          src: require('../assets/images/avocadolachskugelartshotfrontal.jpg'),
        },
        {
          src: require('../assets/images/avocadolachskugelartshot.jpg'),
        },
      ],
      lachsrollen: [
        {
          src: require('../assets/images/lachsrollenfrontalshow.jpg'),
        },
        {
          src: require('../assets/images/lachsrollenvonoben.jpg'),
        },
      ],
      tempuracalifornia: [
        {
          src: require('../assets/images/tempuracaliforniagalerie.jpg'),
        },
        {
          src: require('../assets/images/tempuracaliforniavonoben.jpg'),
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
