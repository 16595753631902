<template>
  <div class="home">
    <v-carousel hide-delimiters cycle>
      <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src">
        <v-row class="fill-height" align="center" justify="center">
          <div class="text-h1 white--text">
            {{ item.title }}
          </div>
        </v-row></v-carousel-item
      >
    </v-carousel>
    <div class="container">
      <backtovorspeise></backtovorspeise>
    </div>
    <v-card>
      <v-card-title class="display-3">
        Vorspeise
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="desserts" :search="search" :items-per-page="-1">
        <!-- Dieser Slot macht Texte fett -->
        <template v-slot:item.speise="{ item }">
          <b>{{ item.speise }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template></v-data-table
      >
    </v-card>
  </div>
</template>

<script>
import Backtovorspeise from './Backtovorspeise.vue';
export default {
  components: { Backtovorspeise },
  name: 'Vorspeise',
  data() {
    return {
      items: [
        {
          src: require('../assets/images/gebackenegarnelenmithintergrund.jpg'),
          title: '',
        },
        {
          src: require('../assets/images/minifruehlingsrollemithintergrund.jpg'),
          title: '',
        },
      ],
      search: '',
      headers: [
        {
          text: 'Nummer',
          align: 'start',
          sortable: false,
          value: 'nummer',
        },
        { text: 'Speise', value: 'speise' },
        { text: 'Anzahl', value: 'anzahl' },
        { text: 'Allergene', value: 'allergene' },
        { text: 'Preis', value: 'preis' },
      ],
      desserts: [
        {
          nummer: 10,
          speise: 'Gebackene Garnelen',
          anzahl: '(6 Stk.)',
          allergene: '(A, F, R)',
          preis: '5,90 €',
        },
        {
          nummer: 11,
          speise: 'Mini Frühlingsrollen (5 Stk.)',
          anzahl: '(5 Stk.)',
          allergene: '(A, F)',
          preis: '3,60 €',
        },
        {
          nummer: 12,
          speise: 'Gebackene Wan-Tan',
          anzahl: '(5 Stk.)',
          allergene: '(A, F)',
          preis: '3,60 €',
        },
        {
          nummer: 13,
          speise: 'Gebackene Tofu',
          anzahl: '',
          allergene: '(A, F, N)',
          preis: '3,60 €',
        },
        {
          nummer: 14,
          speise: 'Frühlingsrolle',
          anzahl: '',
          allergene: '(A, F)',
          preis: '3,80 €',
        },
        {
          nummer: 15,
          speise: 'Gegrillter Hühnerspieß',
          anzahl: '(2 Stk.)',
          allergene: '(A, F)',
          preis: '4,60 €',
        },
        {
          nummer: 16,
          speise: 'Gegrillte Fleischtaschen',
          anzahl: '(5 Stk.)',
          allergene: '(A, F)',
          preis: '3,80 €',
        },
        {
          nummer: 17,
          speise: 'X-Sushi Maki',
          anzahl: '(3 Stk.)',
          allergene: '(D)',
          preis: '5,30 €',
        },
        {
          nummer: 18,
          speise: 'Hummer Chips',
          anzahl: '',
          allergene: '',
          preis: '3,50 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0,0);
    }
  },
  created () {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
