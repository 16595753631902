<template>
  <div class="home">
    <v-carousel hide-delimiters cycle>
      <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src">
        <v-row class="fill-height" align="center" justify="center">
          <div class="text-h1 white--text">
            {{ item.title }}
          </div>
        </v-row></v-carousel-item
      >
    </v-carousel>
    <v-card>
      <v-card-title class="display-3">
        Wein gespritzt
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="desserts" :search="search" :items-per-page="-1"
        ><template v-slot:item.weingespritzt="{ item }">
          <b>{{ item.weingespritzt }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template></v-data-table
      >
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Weingespritzt',
  data() {
    return {
      items: [
        {
          src: require('../assets/images/weingespritzt.jpg'),
          title: '',
        },
        {
          src: require('../assets/images/weissrotgespritzt.jpg'),
          title: '',
        },
        {
          src: require('../assets/images/weissrotunten.jpg'),
          title: '',
        },
        {
          src: require('../assets/images/weissrotoben.jpg'),
          title: '',
        },
      ],
      search: '',
      headers: [
        { text: 'Weingespritzt', value: 'weingespritzt' },
        { text: 'Menge', value: 'menge' },
        { text: 'Preis', value: 'preis' },
      ],
      desserts: [
        {
          weingespritzt: 'Gespritzter (weiß oder rot)',
          menge: '1/4 l',
          preis: '3,70 €',
        },
        {
          weingespritzt: 'Kaiserspritzer (mit Wein gespritzt)',
          menge: '1/4 l',
          preis: '4,30 €',
        },
        {
          weingespritzt: 'Hugo Spritzer',
          menge: '1/4 l',
          preis: '4,80 €',
        },
        {
          weingespritzt: 'Aperol Spritzer',
          menge: '1/4 l',
          preis: '4,70 €',
        },
        {
          weingespritzt: 'Prosecco Aperol (mit Prosecco gespritzt)',
          menge: '1/4 l',
          preis: '5,20 €',
        },
        // {
        //   weingespritzt: 'Almdudler Weiß',
        //   menge: '1/4 l',
        //   preis: '4,20 €',
        // },
        // {
        //   weingespritzt: 'Rotwein mit Cola',
        //   menge: '1/4 l',
        //   preis: '4,20 €',
        // },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
