<template>
  <div class="d-flex flex-wrap mt-2 container">
    <!-- <div class="container mb-2">
      <v-divider class="mx-4" color="black"></v-divider>
      <h1 class="text-center display-3 font-weight-light mt-2 mb-2">
        Neue Gerichte
      </h1>
      <v-divider class="mx-4 mb-6" color="black"></v-divider>
      <backtohome></backtohome>
    </div> -->
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-card
          class="mx-auto"
          max-width="344"
          style="background-color: #212121;"
        >
          <v-carousel
            hide-delimiters
            cycle
            max-height="20%"
            background-size="cover"
          >
            <v-carousel-item
              v-for="(item, i) in entebowl"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
          <v-alert
            dense
            max-width="50%"
            color="green"
            class="text-center mx-auto mt-4"
          >
            NEUHEIT
          </v-alert>
          <v-card-title
            class="justify-center white--text font-weight-light"
            style="background-color: #212121; padding:0;"
          >
            ENTE BOWL
          </v-card-title>
          <v-card-subtitle
            class="text-center white--text font-weight-light mt-1"
            style="background-color: #212121;"
          >
            (Sushi Reis, Edamame, Gurke, Cherrytomaten, Mais, Avocado, Karotten,
            Sesam, knusprige Ente)<br />
            12,90 €
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card
          class="mx-auto"
          max-width="344"
          style="background-color: #212121;"
        >
          <v-carousel
            hide-delimiters
            cycle
            max-height="20%"
            background-size="cover"
          >
            <v-carousel-item
              v-for="(item, i) in garnelenbowl"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
          <v-alert
            dense
            max-width="50%"
            color="green"
            class="text-center mx-auto mt-4"
          >
            NEUHEIT
          </v-alert>
          <v-card-title
            class="justify-center white--text font-weight-light"
            style="background-color: #212121; padding:0;"
          >
            GARNELEN BOWL
          </v-card-title>
          <v-card-subtitle
            class="text-center white--text font-weight-light mt-1"
            style="background-color: #212121;"
          >
            (Sushi Reis, Edamame, Gurke, Cherrytomaten, Mais, Sesam, Avocado,
            Karotten, gebackene Garnelen)<br />
            12,90 €
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card
          class="mx-auto"
          max-width="344"
          style="background-color: #212121;"
        >
          <v-carousel
            hide-delimiters
            cycle
            max-height="20%"
            background-size="cover"
          >
            <v-carousel-item
              v-for="(item, i) in huehnerfleischbowl"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
          <v-alert
            dense
            max-width="50%"
            color="green"
            class="text-center mx-auto mt-4"
          >
            NEUHEIT
          </v-alert>
          <v-card-title
            class="justify-center white--text font-weight-light"
            style="background-color: #212121; padding:0;"
          >
            HÜHNERFLEISCH BOWL
          </v-card-title>
          <v-card-subtitle
            class="text-center white--text font-weight-light mt-1"
            style="background-color: #212121;"
          >
            (Sushi Reis, Edamame, Gurke, Cherrytomaten, Mais, Avocado,
            Karotten, Sesam, gebratenes Hühnerfleisch)<br />
            11,90 €
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card
          class="mx-auto"
          max-width="344"
          style="background-color: #212121;"
        >
          <v-carousel
            hide-delimiters
            cycle
            max-height="20%"
            background-size="cover"
          >
            <v-carousel-item
              v-for="(item, i) in tofubowl"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
          <v-alert
            dense
            max-width="50%"
            color="green"
            class="text-center mx-auto mt-4"
          >
            NEUHEIT
          </v-alert>
          <v-card-title
            class="justify-center white--text font-weight-light"
            style="background-color: #212121; padding:0;"
          >
            TOFU BOWL
          </v-card-title>
          <v-card-subtitle
            class="text-center white--text font-weight-light mt-1"
            style="background-color: #212121;"
          >
            (Sushi Reis, Edamame, Gurke, Cherrytomaten, Mais, Avocado,
            Karotten, Sesam, gebratener Tofu)<br />
            11,90 €
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card
          class="mx-auto"
          max-width="344"
          style="background-color: #212121;"
        >
          <v-carousel
            hide-delimiters
            cycle
            max-height="20%"
            background-size="cover"
          >
            <v-carousel-item
              v-for="(item, i) in thunfischbowl"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
          <v-alert
            dense
            max-width="50%"
            color="green"
            class="text-center mx-auto mt-4"
          >
            NEUHEIT
          </v-alert>
          <v-card-title
            class="justify-center white--text font-weight-light"
            style="background-color: #212121; padding:0;"
          >
            THUNFISCH BOWL
          </v-card-title>
          <v-card-subtitle
            class="text-center white--text font-weight-light mt-1"
            style="background-color: #212121;"
          >
            (Sushi Reis, Edamame, Gurke, Cherrytomaten, Mais, Avocado, Karotten,
           Sesam, Thunfisch)<br />
            12,90 €
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card
          class="mx-auto"
          max-width="344"
          style="background-color: #212121;"
        >
          <v-carousel
            hide-delimiters
            cycle
            max-height="20%"
            background-size="cover"
          >
            <v-carousel-item
              v-for="(item, i) in lachsbowl"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
          <v-alert
            dense
            max-width="50%"
            color="green"
            class="text-center mx-auto mt-4"
          >
            NEUHEIT
          </v-alert>
          <v-card-title
            class="justify-center white--text font-weight-light"
            style="background-color: #212121; padding:0;"
          >
            LACHS BOWL
          </v-card-title>
          <v-card-subtitle
            class="text-center white--text font-weight-light mt-1"
            style="background-color: #212121;"
          >
            (Sushi Reis, Edamame, Gurke, Cherrytomaten, Sesam, Mais, Avocado,
            Karotten, Lachs)<br />
            12,90 €
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>

    <div class="container">
      <h1
        class="text-center display-2 font-weight-thin mt-6"
        style="color: #1B5E20"
      >
        Sauce auswählen
      </h1>
      <br />
      <div class="container text-center">
        <v-alert text outlined color="#FF6F00" icon="mdi-fire">
          SCHARFE MAYO SAUCE
        </v-alert>
        <v-alert text outlined color="#F44336" icon="mdi-fire">
          CHILLI SAUCE
        </v-alert>
        <v-alert text outlined color="#FF3D00" icon="mdi-fire">
          SÜß-CHILLI SAUCE
        </v-alert>
        <v-alert text outlined color="#6D4C41">
          SESAM SAUCE
        </v-alert>
        <!-- <v-alert text outlined color="#4E342E">
          POKE SAUCE
        </v-alert> -->
        <v-alert text outlined color="#3E2723">
          TERIYAKI SAUCE
        </v-alert>
      </div>
      <h1
        class="text-center display-2 font-weight-thin mt-6"
        style="color: #1B5E20"
      >
        Low carb option
      </h1>
      <br>
      <div class="container text-center">
        <v-alert text outlined color="#43A047">
          Anstatt Sushi-Reis auch mit Zucchini möglich!
        </v-alert>
      </div>
    </div>
  </div>
</template>

<script>
// import Backtohome from './Backtohome.vue';
export default {
  // components: { Backtohome },
  data() {
    return {
      entebowl: [
        {
          src: require('../assets/images/entebowloben.png'),
        },
        {
          src: require('../assets/images/entebowlfront.png'),
        },
      ],
      garnelenbowl: [
        {
          src: require('../assets/images/garnelenbowloben.png'),
        },
        {
          src: require('../assets/images/garnelenbowlfront.png'),
        },
      ],
      huehnerfleischbowl: [
        {
          src: require('../assets/images/huehnerfleischoben.png'),
        },
        {
          src: require('../assets/images/huehnerfleischfront.png'),
        },
      ],
      tofubowl: [
        {
          src: require('../assets/images/tofubowloben.png'),
        },
        {
          src: require('../assets/images/tofubowlfront.png'),
        },
      ],
      thunfischbowl: [
        {
          src: require('../assets/images/thunfischbowloben.png'),
        },
        {
          src: require('../assets/images/thunfischfront.png'),
        },
      ],
      lachsbowl: [
        {
          src: require('../assets/images/lachsbowloben.png'),
        },
        {
          src: require('../assets/images/lachsbowlfront.png'),
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
