import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Oeffnungszeiten from '../views/Oeffnungszeiten.vue';
// import Speisekarte from '../views/Speisekarte.vue';
import Allergene from '../views/Allergene.vue';
import Vorspeise from '../views/Vorspeise.vue';
import Hauptspeise from '../views/Hauptspeise.vue';
import Knusprigeente from '../views/Knusprigeente.vue';
import Spezialitaeten from '../views/Spezialitaeten.vue';
import Vorspeisestandard from '../views/Vorspeisestandard.vue';
import Suppen from '../views/Suppen.vue';
import Dimsum from '../views/Dimsum.vue';
import Huehnerfleisch from '../views/Huehnerfleisch.vue';
import Rindfleisch from '../views/Rindfleisch.vue';
import Schweinefleisch from '../views/Schweinefleisch.vue';
import Kontakt from '../views/Kontakt.vue';
import Fisch from '../views/Fisch.vue';
import Garnelen from '../views/Garnelen.vue';
import Nudeln from '../views/Nudeln.vue';
import Udon from '../views/Udon.vue';
import Sushi from '../views/Sushi.vue';
import Maki from '../views/Maki.vue';
import Makispecial from '../views/Makispecial.vue';
import Newdishes from '../views/Newdishes.vue';
import Mittagsmenue from '../views/Mittagsmenue.vue';
import Mittagsmenuekarte from '../views/Mittagsmenuekarte.vue';
import Bentolunchboxkarte from '../views/Bentolunchboxkarte.vue';
import Salate from '../views/Salate.vue';
import Sashimi from '../views/Sashimi.vue';
import Temakis from '../views/Temakis.vue';
import Gemischterteller from '../views/Gemischterteller.vue';
import Gemuese from '../views/Gemuese.vue';
import Reis from '../views/Reis.vue';
import Beilagen from '../views/Beilagen.vue';
import Sushialacarte from '../views/Sushialacarte.vue';
import Ramen from '../views/Ramen.vue';
import Bentobox from '../views/Bentobox.vue';
import Nachspeise from '../views/Nachspeise.vue';
import Gebackenefruechte from '../views/Gebackenefruechte.vue';
import Eisfruechte from '../views/Eisfruechte.vue';
// import Getraenkekarte from '../views/Getraenkekarte.vue';
import Aperitifs from '../views/Aperitifs.vue';
import Bier from '../views/Bier.vue';
import Warmegetraenke from '../views/Warmegetraenke.vue';
import Alkoholfrei from '../views/Alkoholfrei.vue';
import Offeneweine from '../views/Offeneweine.vue';
import Weisswein from '../views/Weisswein.vue';
import Rotwein from '../views/Rotwein.vue';
import Asiatischerwein from '../views/Asiatischerwein.vue';
import Weingespritzt from '../views/Weingespritzt.vue';
import Restaurant from '../views/Restaurant.vue';
import Impressum from '../views/Impressum.vue';
import Bestseller from '../views/Bestseller.vue';
import Galerieeins from '../views/Galerieeins.vue';
import Speisekartenew from '../views/Speisekartenew.vue';
import Getraenkekartenew from '../views/Getraenkekartenew.vue';
import Bowls from '../views/Bowls.vue';
import Datenschutz from '../views/Datenschutz.vue';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/oeffnungszeiten',
    name: 'Oeffnungszeiten',
    component: Oeffnungszeiten,
  },
  // {
  //   path: '/speisekarte',
  //   name: 'Speisekarte',
  //   component: Speisekarte,
  // },
  {
    path: '/allergene',
    name: 'Allergene',
    component: Allergene,
  },
  {
    path: '/vorspeise',
    name: 'Vorspeise',
    component: Vorspeise,
  },
  {
    path: '/hauptspeise',
    name: 'Hauptspeise',
    component: Hauptspeise,
  },
  {
    path: '/knusprigeente',
    name: 'Knusprigeente',
    component: Knusprigeente,
  },
  {
    path: '/spezialitaeten',
    name: 'Spezialitaeten',
    component: Spezialitaeten,
  },
  {
    path: '/vorspeisestandard',
    name: 'Vorspeisestandard',
    component: Vorspeisestandard,
  },
  {
    path: '/suppen',
    name: 'Suppen',
    component: Suppen,
  },
  {
    path: '/dimsum',
    name: 'Dimsum',
    component: Dimsum,
  },
  {
    path: '/huehnerfleisch',
    name: 'Huehnerfleisch',
    component: Huehnerfleisch,
  },
  {
    path: '/rindfleisch',
    name: 'Rindfleisch',
    component: Rindfleisch,
  },
  {
    path: '/schweinefleisch',
    name: 'Schweinefleisch',
    component: Schweinefleisch,
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: Kontakt,
  },
  {
    path: '/fisch',
    name: 'Fisch',
    component: Fisch,
  },
  {
    path: '/garnelen',
    name: 'Garnelen',
    component: Garnelen,
  },
  {
    path: '/nudeln',
    name: 'Nudeln',
    component: Nudeln,
  },
  {
    path: '/udon',
    name: 'Udon',
    component: Udon,
  },
  {
    path: '/sushi',
    name: 'Sushi',
    component: Sushi,
  },
  {
    path: '/maki',
    name: 'Maki',
    component: Maki,
  },
  {
    path: '/makispecial',
    name: 'Makispecial',
    component: Makispecial,
  },
  {
    path: '/newdishes',
    name: 'Newdishes',
    component: Newdishes,
  },
  {
    path: '/mittagsmenue',
    name: 'Mittagsmenue',
    component: Mittagsmenue,
  },
  {
    path: '/mittagsmenuekarte',
    name: 'Mittagsmenuekarte',
    component: Mittagsmenuekarte,
  },
  {
    path: '/bentolunchboxkarte',
    name: 'Bentolunchboxkarte',
    component: Bentolunchboxkarte,
  },
  {
    path: '/salate',
    name: 'Salate',
    component: Salate,
  },
  {
    path: '/sashimi',
    name: 'Sashimi',
    component: Sashimi,
  },
  {
    path: '/temakis',
    name: 'Temakis',
    component: Temakis,
  },
  {
    path: '/gemischterteller',
    name: 'Gemischterteller',
    component: Gemischterteller,
  },
  {
    path: '/gemuese',
    name: 'Gemuese',
    component: Gemuese,
  },
  {
    path: '/reis',
    name: 'Reis',
    component: Reis,
  },
  {
    path: '/beilagen',
    name: 'Beilagen',
    component: Beilagen,
  },
  {
    path: '/sushialacarte',
    name: 'Sushi á la carte',
    component: Sushialacarte,
  },
  {
    path: '/ramen',
    name: 'Ramen',
    component: Ramen,
  },
  {
    path: '/bentobox',
    name: 'Bentobox',
    component: Bentobox,
  },
  {
    path: '/nachspeise',
    name: 'Nachspeise',
    component: Nachspeise,
  },
  {
    path: '/gebackenefruechte',
    name: 'Gebackenefruechte',
    component: Gebackenefruechte,
  },
  {
    path: '/eisfruechte',
    name: 'Eisfruechte',
    component: Eisfruechte,
  },
  // {
  //   path: '/getraenkekarte',
  //   name: 'Getraenkekarte',
  //   component: Getraenkekarte,
  // },
  {
    path: '/aperitifs',
    name: 'Aperitifs',
    component: Aperitifs,
  },
  {
    path: '/bier',
    name: 'Bier',
    component: Bier,
  },
  {
    path: '/warmegetraenke',
    name: 'Warmegetraenke',
    component: Warmegetraenke,
  },
  {
    path: '/alkoholfrei',
    name: 'Alkoholfrei',
    component: Alkoholfrei,
  },
  {
    path: '/offeneweine',
    name: 'Offeneweine',
    component: Offeneweine,
  },
  {
    path: '/weisswein',
    name: 'Weisswein',
    component: Weisswein,
  },
  {
    path: '/rotwein',
    name: 'Rotwein',
    component: Rotwein,
  },
  {
    path: '/asiatischerwein',
    name: 'Asiatischerwein',
    component: Asiatischerwein,
  },
  {
    path: '/weingespritzt',
    name: 'Weingespritzt',
    component: Weingespritzt,
  },
  {
    path: '/restaurant',
    name: 'Restaurant',
    component: Restaurant,
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum,
  },
  {
    path: '/bestseller',
    name: 'Bestseller',
    component: Bestseller,
  },
  {
    path: '/galerieeins',
    name: 'Galerieeins',
    component: Galerieeins,
  },
  {
    path: '/speisekartenew',
    name: 'Speisekartenew',
    component: Speisekartenew,
  },
  {
    path: '/getraenkekartenew',
    name: 'getraenkekartenew',
    component: Getraenkekartenew,
  },
  {
    path: '/bowls',
    name: 'bowls',
    component: Bowls,
  },
  {
    path: '/datenschutz',
    name: 'datenschutz',
    component: Datenschutz,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
