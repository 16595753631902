<template>
  <div class="container">
    <v-btn class="ma-2" outlined large color="green" to="/">
      <v-icon dark left>mdi-arrow-left-circle</v-icon>Back
    </v-btn>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
