<template>
  <div class="home">
    <v-carousel
      hide-delimiters
      hide-arrows
      :left-control-icon="false"
      :right-control-icon="false"
    >
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        :src="item.src"
        reverse-transition="fade-transition"
        transition="fade-transition"
      ></v-carousel-item>
    </v-carousel>
    <!-- <div class="container">
      <backtohauptspeise></backtohauptspeise>
    </div> -->
    <v-card>
      <v-card-title class="display-2">
        Knusprige Ente
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.speise="{ item }">
          <b>{{ item.speise }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template></v-data-table
      >
    </v-card>
  </div>
</template>

<script>
// import Backtohauptspeise from './Backtohauptspeise.vue';
export default {
  // components: { Backtohauptspeise },
  name: 'Knusprigeente',
  data() {
    return {
      items: [
        {
          src: require('../assets/images/knusprigeentemithintergrund.jpg'),
        },
      ],
      search: '',
      headers: [
        {
          text: 'Nummer',
          align: 'start',
          sortable: false,
          value: 'nummer',
        },
        { text: 'Speise', value: 'speise' },
        { text: 'Anzahl', value: 'anzahl' },
        { text: 'Allergene', value: 'allergene' },
        { text: 'Preis', value: 'preis' },
      ],
      desserts: [
        {
          nummer: 'T1',
          speise: 'knusprige Ente mit Gemüse',
          anzahl: '',
          allergene: '(A, F)',
          preis: '11,80 €',
        },
        // {
        //   nummer: 'T2',
        //   speise: 'knusprige Ente mit Achtschätze (scharf)',
        //   anzahl: '',
        //   allergene: '(A, F)',
        //   preis: '17,50 €',
        // },
        {
          nummer: 'T3',
          speise: 'knusprige Ente nach Sichuan Art',
          anzahl: '',
          allergene: '(A, F)',
          preis: '12,60 €',
        },
        {
          nummer: 'T4',
          speise: 'knusprige Ente Chop Suey',
          anzahl: '',
          allergene: '(A,F)',
          preis: '12,60 €',
        },
        {
          nummer: 'T5',
          speise: 'knusprige Ente mit Bambus und Pilzen',
          anzahl: '',
          allergene: '(A, F)',
          preis: '12,60 €',
        },
        {
          nummer: 'T6',
          speise: 'knusprige Ente mit gebratenen Nudeln',
          anzahl: '',
          allergene: '(A, F)',
          preis: '12,40 €',
        },
        {
          nummer: 'T7',
          speise: 'knusprige Ente mit gebratenem Reis',
          anzahl: '',
          allergene: '(A, F)',
          preis: '12,40 €',
        },
        {
          nummer: 'T8',
          speise: 'knusprige Ente mit Ananas',
          anzahl: '',
          allergene: '(A, F)',
          preis: '12,60 €',
        },
        {
          nummer: 'T9',
          speise: 'knusprige Ente mit gegrillter Fleischtasche',
          anzahl: '(5 Stk.)',
          allergene: '(A, F)',
          preis: '12,60 €',
        },
        // {
        //   nummer: 'T10',
        //   speise: 'knusprige Ente mit Hühnerfleisch und Paprika (scharf)',
        //   anzahl: '',
        //   allergene: '(A, F)',
        //   preis: '12,40 €',
        // },
        // {
        //   nummer: 'T11',
        //   speise: 'knusprige Ente mit Curry und Hühnerfleisch',
        //   anzahl: '',
        //   allergene: '(A, F)',
        //   preis: '12,40 €',
        // },
        // {
        //   nummer: 'T12',
        //   speise: 'knusprige Ente mit knusprigem Huhn',
        //   anzahl: '',
        //   allergene: '(A, F)',
        //   preis: '12,40 €',
        // },
        {
          nummer: 'T13',
          speise: 'knusprige Ente mit Thai Sauce (scharf)',
          anzahl: '',
          allergene: '(A, F)',
          preis: '13,20 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
