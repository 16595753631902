<template>
  <div class="container">
    <!-- <div class="container mb-2 mt-2">
      <v-divider class="mx-4" color="black"></v-divider>
      <h1 class="text-center display-3 font-weight-light mt-2 mb-2">
        Speisekarte
      </h1>
      <v-divider class="mx-4 mb-6" color="black"></v-divider>
    </div> -->
    <backtohome></backtohome>
    <div class="d-flex flex-wrap">
      <!-- <v-card> -->
      <v-tabs centered color="#C5B358">
        <!-- neue Gerichte -->
        <v-tab href="#neuegerichte">Neue Gerichte</v-tab>
        <v-tab-item value="neuegerichte"><newdishes></newdishes></v-tab-item>
        <!-- Bestseller -->
        <v-tab href="#bestseller">Bestseller</v-tab>
        <v-tab-item value="bestseller"><bestseller></bestseller></v-tab-item>
        <!-- Bestseller -->
        <v-tab href="#bowls">Bowls</v-tab>
        <v-tab-item value="bowls"><bowls></bowls></v-tab-item>
        <!-- Sushi -->
        <v-tab href="#sushi">Sushi</v-tab>
        <v-tab-item value="sushi"><sushi></sushi></v-tab-item>
        <!-- Sashimi -->
        <v-tab href="#sashimi">Sashimi</v-tab>
        <v-tab-item value="sashimi"><sashimi></sashimi></v-tab-item>
        <!-- Makis -->
        <v-tab href="#makis">Makis</v-tab>
        <v-tab-item value="makis"><maki></maki></v-tab-item>
        <!-- Special Makis -->
        <v-tab href="#specialmakis">special Makis</v-tab>
        <v-tab-item value="specialmakis"
          ><makispecial></makispecial
        ></v-tab-item>
        <!-- Temakis -->
        <v-tab href="#temakis">Temakis</v-tab>
        <v-tab-item value="temakis"><temakis></temakis></v-tab-item>
        <!-- Gemischter Teller -->
        <v-tab href="#gemischterteller">Gemischter Teller</v-tab>
        <v-tab-item value="gemischterteller"
          ><gemischterteller></gemischterteller
        ></v-tab-item>
        <!-- Spezialitäten -->
        <v-tab href="#spezialitaeten">Spezialitäten</v-tab>
        <v-tab-item value="spezialitaeten"
          ><spezialitaeten></spezialitaeten
        ></v-tab-item>
        <!-- Gemüse -->
        <v-tab href="#gemuese">Gemüse</v-tab>
        <v-tab-item value="gemuese"><gemuese></gemuese></v-tab-item>
        <!-- Hühnerfleisch -->
        <v-tab href="#huehnerfleisch">Hühnerfleisch</v-tab>
        <v-tab-item value="huehnerfleisch"
          ><huehnerfleisch></huehnerfleisch
        ></v-tab-item>
        <!-- Rindfleisch -->
        <v-tab href="#rindfleisch">Rindfleisch</v-tab>
        <v-tab-item value="rindfleisch"><rindfleisch></rindfleisch></v-tab-item>
        <!-- Schweinefleisch -->
        <!-- <v-tab href="#schweinefleisch">Schweinefleisch</v-tab>
        <v-tab-item value="schweinefleisch"
          ><schweinefleisch></schweinefleisch
        ></v-tab-item> -->
        <!-- Knusprige Ente -->
        <v-tab href="#knusprigeente">Knusprige Ente</v-tab>
        <v-tab-item value="knusprigeente"
          ><knusprigeente></knusprigeente
        ></v-tab-item>
        <!-- Fisch -->
        <v-tab href="#fisch">Fisch</v-tab>
        <v-tab-item value="fisch"><fisch></fisch></v-tab-item>
        <!-- Garnelen -->
        <v-tab href="#garnelen">Garnelen</v-tab>
        <v-tab-item value="garnelen"><garnelen></garnelen></v-tab-item>
        <!-- Nudeln -->
        <v-tab href="#nudeln">Nudeln</v-tab>
        <v-tab-item value="nudeln"><nudeln></nudeln></v-tab-item>
        <!-- Udon -->
        <v-tab href="#udon">Udon</v-tab>
        <v-tab-item value="udon"><udon></udon></v-tab-item>
        <!-- Reis -->
        <v-tab href="#reis">Reis</v-tab>
        <v-tab-item value="reis"><reis></reis></v-tab-item>
        <!-- Ramen -->
        <v-tab href="#ramen">Ramen</v-tab>
        <v-tab-item value="ramen"><ramen></ramen></v-tab-item>
        <!-- Bento Box -->
        <v-tab href="#bentobox">Bento Box</v-tab>
        <v-tab-item value="bentobox"><bentobox></bentobox></v-tab-item>
        <!-- vorspeise -->
        <v-tab href="#vorspeise">Vorspeise</v-tab>
        <v-tab-item value="vorspeise"><vorspeisenew></vorspeisenew></v-tab-item>
        <!-- Suppe -->
        <v-tab href="#suppen">Suppen</v-tab>
        <v-tab-item value="suppen"><suppennew></suppennew></v-tab-item>
        <!-- Dim sum -->
        <v-tab href="#dimsum">Dim Sum</v-tab>
        <v-tab-item value="dimsum"><dimsumnew></dimsumnew></v-tab-item>
        <!-- Salate -->
        <v-tab href="#salate">Salate</v-tab>
        <v-tab-item value="salate"><salate></salate></v-tab-item>
        <!-- Beilagen -->
        <v-tab href="#beilagen">Beilagen</v-tab>
        <v-tab-item value="beilagen"><beilagen></beilagen></v-tab-item>
        <!-- Nachspeise -->
        <v-tab href="#nachspeise">Nachspeise</v-tab>
        <v-tab-item value="nachspeise"
          ><nachspeisenew></nachspeisenew
        ></v-tab-item>
        <!-- Sushi a la Carte -->
        <!-- <v-tab href="#sushialacarte">Sushi á la carte</v-tab>
        <v-tab-item value="sushialacarte"
          ><sushialacarte></sushialacarte
        ></v-tab-item> -->
        <!-- Allergene -->
        <v-tab href="#allergene">Allergene</v-tab>
        <v-tab-item value="allergene"><allergene></allergene></v-tab-item>
      </v-tabs>
      <!-- </v-card> -->
    </div>
  </div>
</template>

<script>
import Backtohome from './Backtohome.vue';
import Vorspeisenew from '../views/Vorspeisenew.vue';
import Suppennew from '../views/Suppennew.vue';
import Dimsumnew from '../views/Dimsumnew.vue';
import Newdishes from '../views/Newdishes.vue';
import Salate from '../views/Salate.vue';
import Beilagen from '../views/Beilagen.vue';
import Nachspeisenew from '../views/Nachspeisenew.vue';
import Spezialitaeten from '../views/Spezialitaeten.vue';
import Gemuese from '../views/Gemuese.vue';
import Huehnerfleisch from '../views/Huehnerfleisch.vue';
import Rindfleisch from '../views/Rindfleisch.vue';
// import Schweinefleisch from '../views/Schweinefleisch.vue';
import Knusprigeente from './Knusprigeente.vue';
import Fisch from '../views/Fisch.vue';
import Garnelen from './Garnelen.vue';
import Nudeln from '../views/Nudeln.vue';
import Udon from '../views/Udon.vue';
import Reis from '../views/Reis.vue';
import Bentobox from './Bentobox.vue';
import Sushi from '../views/Sushi.vue';
import Sashimi from '../views/Sashimi.vue';
import Maki from '../views/Maki.vue';
import Makispecial from '../views/Makispecial.vue';
import Temakis from './Temakis.vue';
import Gemischterteller from './Gemischterteller.vue';
// import Sushialacarte from './Sushialacarte.vue';
import Allergene from '../views/Allergene.vue';
import Bestseller from './Bestseller.vue';
import Ramen from './Ramen.vue';
import Bowls from './Bowls.vue';
// import  from '../views/.vue';

export default {
  components: {
    Backtohome,
    Vorspeisenew,
    Suppennew,
    Dimsumnew,
    Newdishes,
    Salate,
    Beilagen,
    Nachspeisenew,
    Spezialitaeten,
    Gemuese,
    Huehnerfleisch,
    Rindfleisch,
    // Schweinefleisch,
    Knusprigeente,
    Fisch,
    Garnelen,
    Nudeln,
    Udon,
    Reis,
    Bentobox,
    Sushi,
    Sashimi,
    Maki,
    Makispecial,
    Temakis,
    Gemischterteller,
    // Sushialacarte,
    Allergene,
    Bestseller,
    Ramen,
    Bowls,
  },
  name: 'Speisekarte',
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>
<style lang="scss" scoped></style>
