<template>
  <!-- WEINACHTSOEFFNUNGSZEITEN -->
  <div class="container" style="background-color:">
    <!-- <v-divider class="mx-4" color="black"></v-divider>
    <h1 class="text-center mt-4 mb-4 display-1 font-weight-thin">
      FEIERTAGS <br />
      ÖFFNUNGSZEITEN
    </h1>
    <v-divider class="mx-4" color="black"></v-divider> -->

    <div class="d-flex flex-wrap mt-10">
      <v-row>
        <!-- <v-col cols="12" sm="6" md="4">
          <v-card
            class="mx-auto white--text"
            max-width="450"
            height="220"
            to="/oeffnungszeiten"
            style="background-color: #212121;"
          >
            <v-card-subtitle class="text-center"
              ><v-alert type="success" color="green"
                >GEÖFFNET</v-alert
              ></v-card-subtitle
            >
            <v-card-subtitle class="text-center text-h6 white--text">
              am 24.12.2021 <br>11:00 - 15:00
            </v-card-subtitle>
          </v-card>
        </v-col> -->
        <!-- WEIHNACHTSOEFFNUNGSZEITEN -->
        <!-- <div class="container">
          <h3 class="text-center display-3 font-weight-thin mt-6">
            Weihnachts= <br> öffnungszeiten
          </h3>
        </div>
        <v-col cols="24" sm="12" md="6">
          <v-card
            class="mx-auto white--text"
            max-width="450"
            height="220"
            to="/oeffnungszeiten"
            style="background-color:#212121;"
          >
            <v-card-subtitle class="text-center"
              ><v-alert type="error" color="red"
                >GESCHLOSSEN</v-alert
              ></v-card-subtitle
            >
            <v-card-subtitle
              class="text-center text-h6 white--text overline font-weight-light"
            >
              von 23.12 bis 26.12.2023 und <br />
              von 30.12.2023 bis 01.01.2024 <br />GESCHLOSSEN
            </v-card-subtitle>
          </v-card>
        </v-col>
        <v-col cols="24" sm="12" md="6">
          <v-card
            class="mx-auto white--text"
            max-width="450"
            height="220"
            to="/oeffnungszeiten"
            style="background-color:#212121;"
          >
            <v-card-subtitle class="text-center"
              ><v-alert type="info" color="green"
                >NORMALBETRIEB</v-alert
              ></v-card-subtitle
            >
            <v-card-subtitle
              class="text-center text-h6 white--text overline font-weight-light"
            >
              ab 02.01.2024 <br />
              NORMALBETRIEB
            </v-card-subtitle>
          </v-card>
        </v-col> -->
      </v-row>
    </div>
    <br>
    <!-- <div class="container text-center mt-10">
      <p class="display-1 font-italic">
        Wir wünschen Ihnen frohe Weihnachten und freuen uns <br />auf ein
        baldiges Wiedersehen
      </p>
    </div> -->
    <!-- WEIHNACHTSOEFFNUNGSZEITEN ENDE -->
    <!-- <v-divider class="mx-4 mt-4" color="black"></v-divider> -->
    <div class="container">
      <h1 class="text-center display-3 font-weight-thin font-italic mt-6">
        Öffnungszeiten
      </h1>
    </div>
    <!-- <v-divider class="mx-4" color="black"></v-divider> -->
    <backtohome></backtohome>
    <div class="d-flex flex-wrap mt-6">
      <v-row>
        <v-col cols="24" sm="12" md="6">
          <v-card
            class="mx-auto white--text"
            max-width="450"
            height="220"
            to="/oeffnungszeiten"
            style="background-color: #212121;"
          >
            <!-- <v-img src="../assets/images/opentime.jpg" height="200px"></v-img> -->
            <v-card-subtitle class="text-center"
              ><v-alert type="success" color="green"
                >GEÖFFNET</v-alert
              ></v-card-subtitle
            >
            <v-card-subtitle
              class="text-center text-h6 white--text overline font-weight-light"
            >
              SO - FR <br />
              11:30 - 22:00
            </v-card-subtitle>
          </v-card>
        </v-col>
        <v-col cols="24" sm="12" md="6">
          <v-card
            class="mx-auto white--text"
            max-width="450"
            height="220"
            to="/oeffnungszeiten"
            style="background-color:#212121;"
          >
            <!-- <v-img src="../assets/images/opentime.jpg" height="200px"></v-img> -->
            <v-card-subtitle class="text-center"
              ><v-alert type="error" color="red"
                >GESCHLOSSEN</v-alert
              ></v-card-subtitle
            >
            <v-card-subtitle
              class="text-center text-h6 white--text overline font-weight-light"
            >
              Samstags <br />
              geschlossen
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Backtohome from './Backtohome.vue';
export default {
  components: { Backtohome },
  name: 'Oeffnungszeiten',
  data() {
    return {};
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
