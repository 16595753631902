<template>
  <div class="container">
    <!-- <div class="container mb-2 mt-2">    
    <v-divider class="mx-4" color="black"></v-divider>
    <h1 class="text-center display-3 font-weight-light mt-2 mb-2">Allergene</h1>
    <v-divider class="mx-4 mb-8" color="black"></v-divider>
    <backtospeisekarte></backtospeisekarte>
    </div> -->
    <v-data-table
      :headers="headers"
      :items="Buchstabe"
      hide-default-header
      hide-default-footer
      :items-per-page="-1"
      class="elevation-1"
    >
      <template v-slot:item.name="{ item }">
        <b>{{ item.name }}</b>
      </template>
      <template v-slot:item.allergene="{ item }">
        <b>{{ item.allergene }}</b>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// import Backtospeisekarte from './Backtospeisekarte.vue';
export default {
  // components: { Backtospeisekarte },
  name: 'Allergene',
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Buchstabe',
          value: 'name',
        },
        { text: 'Allergene', value: 'allergene' },
      ],
      Buchstabe: [
        {
          name: '(A)',
          allergene: 'Gluten',
        },
        {
          name: '(B)',
          allergene: 'Krebstiere',
        },
        {
          name: '(C)',
          allergene: 'Eier',
        },
        {
          name: '(D)',
          allergene: 'Fisch',
        },
        {
          name: '(E)',
          allergene: 'Erdnuss',
        },
        {
          name: '(F)',
          allergene: 'Soja',
        },
        {
          name: '(G)',
          allergene: 'Milch oder Laktose',
        },
        {
          name: '(H)',
          allergene: 'Schalenfrüchte',
        },
        {
          name: '(L)',
          allergene: 'Sellerie',
        },
        {
          name: '(M)',
          allergene: 'Senf',
        },
        {
          name: '(N)',
          allergene: 'Sesam',
        },
        {
          name: '(O)',
          allergene: 'Sulfite',
        },
        {
          name: '(P)',
          allergene: 'Lupinen',
        },
        {
          name: '(R)',
          allergene: 'Weichtier',
        },
        {
          name: '(*)',
          allergene: 'enthält eine Phenylalaninquelle',
        },
        {
          name: '(**)',
          allergene: 'kann bei übermäßigen Verzehr abführend wirken',
        },
        {
          name: '(A)',
          allergene: 'Alle Allergene Kennzeichnungen stehen in (Klammer)',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
