<template>
  <div class="container">
    <v-divider class="mx-4 mt-4" color="black"></v-divider>
    <h1 class="text-center mt-4 mb-4 display-2 font-weight-light">
      Mittagsmenü
    </h1>
    <v-divider class="mx-4" color="black"></v-divider>
    <v-row>
      <v-col cols="48" sm="24" md="12">
        <p
          class="body-1"
          style="color: #0277BD; text-align:center; margin-top: 5px"
        >
          Nur <b>WERKTAGS</b> von <b>11.00 bis 15.00 Uhr</b><br />
        </p>
      </v-col>
    </v-row>
    <backtohome></backtohome>
    <div class="d-flex flex-wrap mt-6">
      <v-row>
        <v-col cols="24" sm="12" md="6">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            height="360"
            to="/mittagsmenuekarte"
            style="background-color: #212121;"
          >
            <!-- <v-img src="../assets/images/opentime.jpg" height="200px"></v-img> -->
            <v-card-title
              class="justify-center display-1 font-weight-light white--text"
              color="#212121"
              >Mittagsmenü</v-card-title
            >
            <v-divider class="mx-4" color="white"></v-divider>
            <v-img
              src="../assets/images/mittagsmenuetellerohnehintergrund.png"
              height="200px"
            ></v-img>
            <v-card-subtitle class="text-center white--text font-weight-light overline">
              Jedes Menü mit Suppe oder Frühlingsrolle und Reis
            </v-card-subtitle>
          </v-card>
        </v-col>
        <v-col cols="24" sm="12" md="6">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            height="356"
            to="/bentolunchboxkarte"
            style="background-color:#212121;"
          >
            <!-- <v-img src="../assets/images/opentime.jpg" height="200px"></v-img> -->
            <v-card-title
              class="justify-center display-1 font-weight-light white--text"
              color="#212121"
              >Bentolunchbox</v-card-title
            >
            <v-divider class="mx-4" color="white"></v-divider>
            <v-img
              src="../assets/images/bentoboxohnehintergrund.png"
              height="200px"
            ></v-img>
            <v-card-subtitle class="text-center white--text font-weight-light overline">
              Zu jeder Box gibt es Suppe mit Reis oder Frühlingsrolle mit Reis
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Backtohome from './Backtohome.vue';
export default {
  components: { Backtohome },
  name: 'Oeffnungszeiten',
  data() {
    return {};
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
