<template>
  <div class="home">
    <v-carousel
      hide-delimiters
      hide-arrows
      :left-control-icon="false"
      :right-control-icon="false"
    >
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        :src="item.src"
        reverse-transition="fade-transition"
        transition="fade-transition"
      ></v-carousel-item>
    </v-carousel>
    <!-- <div class="container">
      <backtohauptspeise></backtohauptspeise>
    </div> -->
    <v-card>
      <v-card-title class="display-3">
        Bento Box
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:items="{ item }"
          ><b>{{ item.desserts.speise }}</b></template
        >
        <template v-slot:item.speise="{ item }">
          <b>{{ item.speise }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// import Backtohauptspeise from './Backtohauptspeise.vue';
export default {
  // components: { Backtohauptspeise },
  name: 'Bentobox',
  data() {
    return {
      items: [
        {
          src: require('../assets/images/bentoboxrechts.jpg'),
        },
        {
          src: require('../assets/images/bentoboxfrontalmithintergrund.jpg'),
        },
      ],
      search: '',
      headers: [
        {
          text: 'Nummer',
          align: 'start',
          sortable: false,
          value: 'nummer',
        },
        { text: 'Speise', value: 'speise' },
        { text: 'Inhalt', value: 'inhalt' },
        { text: 'Allergene', value: 'allergene' },
        { text: 'Preis', value: 'preis' },
      ],
      desserts: [
        {
          nummer: 'B1A',
          speise: 'Sushi Bento',
          inhalt: '(4 Sushi, 6 Maki und Obst)',
          allergene: '(A, B, C, D, F, G, R, O)',
          preis: '11,50 €',
        },
        {
          nummer: 'B2A',
          speise: 'Sashimi Bento',
          inhalt: '(9 Sashimi, 3 Maki, 3 Sushi und Obst)',
          allergene: '(A, B, C, D, F, G, H, R, O)',
          preis: '11,50 €',
        },
        {
          nummer: 'B3A',
          speise: 'Maki Bento',
          inhalt: '(12 Maki und Obst)',
          allergene: '(A, B, C, D, F, G, H, R, O)',
          preis: '11,50 €',
        },
        {
          nummer: 'B4A',
          speise: 'Tempura Bento ',
          inhalt: '(gebackene Garnelen, 3 Maki, 3 Sushi und Obst)',
          allergene: '(A, B, C, F, G, H, O, R)',
          preis: '11,50 €',
        },
        {
          nummer: 'B5A',
          speise: 'Vegetarisches Bento',
          inhalt: '(gebackener Tofu mit Gemüse, 3 Makis, 3 Sushi und Obst',
          allergene: '(A, B, C, F, G, H, O)',
          preis: '11,50 €',
        },
        {
          nummer: 'B6A',
          speise: 'Lachs Bento',
          inhalt: '(gegrillter Lachs, 3 Maki, 3 Sushi und Obst)',
          allergene: '(A, B, C, D, E, F, G, H)',
          preis: '11,50 €',
        },
        {
          nummer: 'B7A',
          speise: 'Yakitori Bento ',
          inhalt: '(gegrillter Hühnerspieß, 3 Maki, 3 Sushi und Obst)',
          allergene: '(A, B, C, D, E, F, G, H)',
          preis: '11,50 €',
        },
        {
          nummer: 'B8A',
          speise: 'Tori Bento ',
          inhalt:
            '(gebratenes Hühnerfleisch mit Gemüse, 3 Maki, 3 Sushi und Obst)',
          allergene: '(A, B, D, E, F, H, N)',
          preis: '11,50 €',
        },
        {
          nummer: 'B9A',
          speise: 'Acht Schätze Bento (scharf)',
          inhalt: '(3 Maki, 3 Sushi und Obst)',
          allergene: '(A, C, E, H, N)',
          preis: '11,50 €',
        },
        {
          nummer: 'B10A',
          speise: 'Misoyaki Bento',
          inhalt: '(gegrilter Butterfisch, 3 Maki, 3 Sushi und Obst)',
          allergene: '(A, C, E, H, N)',
          preis: '11,50 €',
        },
        {
          nummer: 'B11A',
          speise: 'Thunfisch Bento ',
          inhalt: '(gegrillter Thunfisch, 3 Maki, 3 Sushi und Obst)',
          allergene: '(A, B, D, E, F, H, N)',
          preis: '11,50 €',
        },
        {
          nummer: 'B12A',
          speise: 'Rindfleisch Bento ',
          inhalt: '(gegrilltes Rindfleisch, 3 Maki, 3 Sushi und Obst)',
          allergene: '(A, B, D, E, F, H, O)',
          preis: '11,50 €',
        },
        {
          nummer: 'B13A',
          speise: 'Akame Bento',
          inhalt: '(knusprige Ente oder Huhn, 3 Maki, 3 Sushi und Obst)',
          allergene: '(A, B, C, D, F, H)',
          preis: '11,50 €',
        },
        {
          nummer: 'B14A',
          speise: 'Sesam Bento',
          inhalt:
            '(gebackenes Hühnerfleisch mit Sesam, 3 Maki, 3 Sushi und Obst)',
          allergene: '(A, B, C, F, H, N)',
          preis: '11,50 €',
        },
        {
          nummer: 'B15A',
          speise: 'Bento süß-sauer ',
          inhalt: '(Hühnerfleisch, 3 Maki, 3 Sushi und Obst)',
          allergene: '(A, B, C, F, H, N)',
          preis: '11,50 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
