<template>
  <div class="home">
    <div class="d-flex flex-wrap mt-6">
      <backtospeisekarte class="mb-6"></backtospeisekarte>
      <v-row>
        <v-col cols="24" sm="12" md="6">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            to="/gebackenefruechte"
            style="background-color: #212121;"
          >
            <v-img
              src="../assets/images/gebackenebananeohnehintergrund.png"
              height="200px"
            ></v-img>

            <v-card-title class="justify-center font-weight-thin">
              gebackene Früchte
            </v-card-title>

            <!-- <v-card-subtitle>
              1,000 miles of wonder
            </v-card-subtitle> -->
          </v-card>
        </v-col>
        <v-col cols="24" sm="12" md="6">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            to="/eisfruechte"
            style="background-color: #212121;"
          >
            <v-img
              src="../assets/images/eiskaffeeohnehintergrund.png"
              height="200px"
            ></v-img>

            <v-card-title class="justify-center font-weight-thin">
              Eis mit Früchte
            </v-card-title>

            <!-- <v-card-subtitle>
              1,000 miles of wonder
            </v-card-subtitle> -->
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Backtospeisekarte from './Backtospeisekarte.vue';
export default {
  components:{Backtospeisekarte},
  name: 'Vorspeise',
  data() {
    return {
      items: [
        {
          src: require('../assets/images/dimsum-uncutted-background.png'),
          title: 'VORSPEISE',
        },
        {
          src: require('../assets/images/fleischtaschen.jpg'),
          title: 'VORSPEISE',
        },
        {
          src: require('../assets/images/rainbowmaki.jpg'),
          title: 'VORSPEISE',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
