<template>
  <div class="home">
    <v-carousel hide-delimiters cycle height="800px" max-width="100%">
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        :src="item.src"
      ></v-carousel-item>
    </v-carousel>
    <br />
    <br />
    <div class="container">
      <backtohome></backtohome>
      <h1 class="text-center display-4 font-weight-thin font-italic mt-6">
        Über uns
      </h1>
    </div>
    <div class="d-flex flex-wrap container">
      <v-row align="center" justify="center">
        <v-col cols="24" sm="12" md="6">
          <v-card class="mx-auto my-12" max-width="350" color="#212121">
            <v-img
              height="300"
              src="../assets/images/restaurantfront.jpg"
            ></v-img>

            <v-card-title
              class="justify-center display-1 font-weight-bold white--text"
              color="#212121"
              >Das Ziel</v-card-title
            >

            <v-divider class="mx-4" color="white"></v-divider>
            <h3
              class="ml-2 font-weight-bold font-italic white--text mt-4 mb-4 mr-2"
            >
              Alles begann vor 21 Jahren mit dem Traum ein eigenes Restaurant zu
              eröffnen. Zu diesem Traum war es auch sehr wichtig authentische
              asiatische Küche für Sie zuzubereiten.
            </h3>
          </v-card>
        </v-col>
        <v-col cols="24" sm="12" md="6">
          <v-card class="mx-auto my-12" max-width="350" color="#212121">
            <v-img
              height="300"
              src="../assets/images/restaurantinnen.jpg"
            ></v-img>

            <v-card-title
              class="justify-center display-1 font-weight-bold white--text"
              >Glashaus Akaz</v-card-title
            >

            <v-divider class="mx-4" color="white"></v-divider>
            <h3
              class="ml-2 font-weight-bold font-italic white--text mt-4 mb-4 mr-2"
            >
              Akaz bietet neben den appetitlichen Gerichten auch ein
              Wohlfühlabiente. Im Weiteren verfügt das Glashaus Akaz tagsüber
              vor allem im Sommer einen spektakulären Belichtung.
            </h3>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div class="container">
      <v-divider class="mx-4" color="black"></v-divider>
      <div class="container text-center">
        <p class="display-1 font-italic">
          Wir freuen uns auf Ihr kommen und Gerichte mit frischen Zutaten für
          Sie zubereiten zu dürfen!
        </p>
      </div>
      <v-divider class="mx-4" color="black"></v-divider>
      <div class="container text-center">
        <p class="display-4 font-italic font-weight-thin">Gallerie</p>
      </div>
      <v-row align="center" justify="center">
        <v-col cols="24" sm="12" md="6">
          <v-card class="mx-auto my-12" max-width="350" color="#212121">
            <v-img
              height="300"
              src="../assets/images/Golden-CloseUp.jpg"
            ></v-img>
          </v-card>
        </v-col>
        <v-col cols="24" sm="12" md="6">
          <v-card class="mx-auto my-12" max-width="350" color="#212121">
            <v-img
              height="300"
              src="../assets/images/Golden-Mix-Frontal.jpg"
            ></v-img>
          </v-card>
        </v-col>
        <v-col cols="24" sm="12" md="6">
          <v-card class="mx-auto my-12" max-width="350" color="#212121">
            <v-img
              height="300"
              src="../assets/images/lachskaviarsushifrontal.png"
            ></v-img>
          </v-card>
        </v-col>
        <v-col cols="24" sm="12" md="6">
          <v-card class="mx-auto my-12" max-width="350" color="#212121">
            <v-img
              height="300"
              src="../assets/images/lachskaviarsushiflambiertfrontal.png"
            ></v-img>
          </v-card>
        </v-col>
        <v-col cols="24" sm="12" md="6">
          <v-card class="mx-auto my-12" max-width="350" color="#212121">
            <v-img
              height="300"
              src="../assets/images/drachenmakiseitlichmitlogo.png"
            ></v-img>
          </v-card>
        </v-col>
        <v-col cols="24" sm="12" md="6">
          <v-card class="mx-auto my-12" max-width="350" color="#212121">
            <v-img
              height="300"
              src="../assets/images/thunfischkaviarsushiflambiertseitlich.png"
            ></v-img>
          </v-card>
        </v-col>
        <v-col cols="24" sm="12" md="6">
          <v-card class="mx-auto my-12" max-width="350" color="#212121">
            <v-img
              height="300"
              src="../assets/images/sushitorteoben.png"
            ></v-img>
          </v-card>
        </v-col>
        <v-col cols="24" sm="12" md="6">
          <v-card class="mx-auto my-12" max-width="350" color="#212121">
            <v-img
              height="300"
              src="../assets/images/lachskaviarsushiflambiertoben.png"
            ></v-img>
          </v-card>
        </v-col>
        <v-col cols="24" sm="12" md="6">
          <v-card class="mx-auto my-12" max-width="350" color="#212121">
            <v-img
              height="300"
              src="../assets/images/sushidonutsfrontal.png"
            ></v-img>
          </v-card>
        </v-col>
        <v-col cols="24" sm="12" md="6">
          <v-card class="mx-auto my-12" max-width="350" color="#212121">
            <v-img
              height="300"
              src="../assets/images/lachskaviarsushioben.png"
            ></v-img>
          </v-card>
        </v-col>
        <v-col cols="24" sm="12" md="6">
          <v-card class="mx-auto my-12" max-width="350" color="#212121">
            <v-img
              height="300"
              src="../assets/images/avocadolachskugelartshot.jpg"
            ></v-img>
          </v-card>
        </v-col>
        <v-col cols="24" sm="12" md="6">
          <v-card class="mx-auto my-12" max-width="350" color="#212121">
            <v-img
              height="300"
              src="../assets/images/tempuracaliforniagalerie.jpg"
            ></v-img>
          </v-card>
        </v-col>
        <v-col cols="24" sm="12" md="6">
          <v-card class="mx-auto my-12" max-width="350" color="#212121">
            <v-img
              height="300"
              src="../assets/images/gemischtertellermithintergrund.jpg"
            ></v-img>
            <!-- <v-card-title
            class="justify-center display-1 font-weight-bold white--text"
            >Gemischter Teller</v-card-title
          > -->
          </v-card>
        </v-col>
        <v-col cols="24" sm="12" md="6">
          <v-card class="mx-auto my-12" max-width="350" color="#212121">
            <v-img
              height="300"
              src="../assets/images/ramenmithintergrund.jpg"
            ></v-img>
            <!-- <v-card-title
            class="justify-center display-1 font-weight-bold white--text"
            >Ramen</v-card-title
          > -->
          </v-card>
        </v-col>
        <v-col cols="24" sm="12" md="6">
          <v-card class="mx-auto my-12" max-width="350" color="#212121">
            <v-img
              height="300"
              src="../assets/images/sashimifrontal.jpg"
            ></v-img>
            <!-- <v-card-title
            class="justify-center display-1 font-weight-bold white--text"
            >Sashimi</v-card-title
          > -->
          </v-card>
        </v-col>
        <v-col cols="24" sm="12" md="6">
          <v-card class="mx-auto my-12" max-width="350" color="#212121">
            <v-img
              height="300"
              src="../assets/images/camparisodanahaufnahme.jpg"
            ></v-img>
            <!-- <v-card-title
            class="justify-center display-1 font-weight-bold white--text"
            >Cam</v-card-title
          > -->
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Backtohome from './Backtohome.vue';
export default {
  components: { Backtohome },
  data() {
    return {
      items: [
        {
          src: require('../assets/images/restaurantlogoblau.png'),
        },
        {
          src: require('../assets/images/restaurantfront.jpg'),
        },
        {
          src: require('../assets/images/restaurantampel.jpg'),
        },
        {
          src: require('../assets/images/restauranteingang.jpg'),
        },
        {
          src: require('../assets/images/restauranthinten.jpg'),
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped>
.bottom-gradient {
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.4) 0%,
    transparent 72px
  );
}

.repeating-gradient {
  background-image: repeating-linear-gradient(
    -45deg,
    rgba(255, 0, 0, 0.25),
    rgba(255, 0, 0, 0.25) 5px,
    rgba(0, 0, 255, 0.25) 5px,
    rgba(0, 0, 255, 0.25) 10px
  );
}
</style>
