<template>
  <div class="home">
    <div class="container d-flex flex-wrap">
      <v-row>
        <v-col cols="48" sm="24" md="12">
          <p
            class="body-1"
            style="color: #0277BD; text-align:center; margin-top: 5px"
            href="tel:+4314162667"
          >
            Rufen Sie uns an und bestellen Sie ihr Essen telefonisch, welches
            Sie anschließen in 15-20 Minuten abholen können.<br />
            <b>Tel.:</b>
            <a href="tel:+4314162667" style="text-decoration: none;">
              +43 1 4162667</a
            >
            <br />
            <b>Mobile:</b>
            <a href="tel:+436602457660" style="text-decoration: none;">
              +43 660 2457660</a
            >
          </p>
        </v-col>
      </v-row>
      <backtohome></backtohome>
      <v-tabs centered color="#C5B358">
        <v-tab href="#abholung">Abholung</v-tab>
        <v-tab-item value="abholung">
          <v-row align="center" justify="center">
            <v-col cols="24" sm="12" md="6">
              <v-card
                class="d-block mx-auto mt-4"
                max-width="400"
                color="#212121"
              >
                <v-card-title class="justify-center white--text headline">
                  ABHOLUNG
                </v-card-title>
                <v-card-subtitle class="text-center white--text overline mt-2">
                  WIR FREUEN UNS, IHR ESSEN FÜR SIE MIT FRISCHEN ZUTATEN
                  ZUBEREITEN ZU DÜRFEN!
                </v-card-subtitle>
                <v-timeline class="white--text">
                  <v-timeline-item>Anrufen</v-timeline-item>
                  <v-timeline-item class="text-right">
                    Das Essen bestellen
                  </v-timeline-item>
                  <v-timeline-item>Das Essen Abholen</v-timeline-item>
                </v-timeline>
                <div class="m-2 text-center">
                  <v-btn class="mx-auto" color="success" href="tel:+4314162667">
                    <span class="mr-2">Anrufen</span>
                    <v-icon>mdi-phone</v-icon>
                  </v-btn>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab href="#lieferung">Lieferung</v-tab>
        <v-tab-item value="lieferung">
          <v-row align="center" justify="center">
            <v-col cols="24" sm="12" md="6">
              <v-card
                class="d-block mx-auto mt-4"
                max-width="400"
                color="#212121"
              >
                <v-card-title class="justify-center white--text headline">
                  FOODORA | LIEFERN LASSEN
                </v-card-title>
                <v-card-subtitle class="text-center white--text overline mt-2">
                  SIE HABEN AUCH DIE MÖGLICHKEIT, GERICHTE VON UNS AUF FOODORA
                  ZU BESTELLEN.
                </v-card-subtitle>
                <v-timeline class="white--text" color="white">
                  <v-timeline-item
                    >Auf "Weiter zu Foodara" klicken</v-timeline-item
                  >
                  <v-timeline-item class="text-right">
                    Das Essen bestellen
                  </v-timeline-item>
                  <v-timeline-item>Das Essen liefern lassen</v-timeline-item>
                </v-timeline>
                <div class="m-2 text-center">
                  <v-btn
                    class="mx-auto"
                    color="#DF1067"
                    href="https://www.mjam.net/restaurant/snkw/akaz?sem_tracker=15161031287&gclid=Cj0KCQiA-K2MBhC-ARIsAMtLKRsa5asRvZMmULySV_51tjZf0ZJAd9KqLYUrZ3ePSyAiens_qB5P-Y8aArynEALw_wcB"
                  >
                    <span class="mr-2 white--text">Weiter zu Foodora</span>
                    <!-- <v-icon>mdi-phone</v-icon> -->
                  </v-btn>
                </div>
              </v-card>
            </v-col>
            <v-col cols="24" sm="12" md="6">
              <v-card class="d-block mx-auto" max-width="400" color="#212121">
                <v-card-title class="justify-center white--text headline">
                  LIEFERANDO | LIEFERN LASSEN
                </v-card-title>
                <v-card-subtitle class="text-center white--text overline mt-2">
                  SIE HABEN AUCH DIE MÖGLICHKEIT, GERICHTE VON UNS AUF
                  LIEFERANDO ZU BESTELLEN.
                </v-card-subtitle>
                <v-timeline class="white--text" color="white">
                  <v-timeline-item
                    >Auf "Weiter zu LIEFERANDO" klicken</v-timeline-item
                  >
                  <v-timeline-item class="text-right">
                    Das Essen bestellen
                  </v-timeline-item>
                  <v-timeline-item>Das Essen liefern lassen</v-timeline-item>
                </v-timeline>
                <div class="m-2 text-center">
                  <v-btn
                    class="mx-auto"
                    color="#F57C00"
                    href="https://www.lieferando.at/speisekarte/akaz?k1111=k1111&gclid=CjwKCAiAhreNBhAYEiwAFGGKPJw83vqnAJr11IQhoQNb8PCcb37isuD6487uy3qaMEos5N3_LFiRsBoCV9YQAvD_BwE&gclsrc=aw.ds"
                  >
                    <span class="mr-2 white--text">Weiter zu LIEFERANDO</span>
                    <!-- <v-icon>mdi-phone</v-icon> -->
                  </v-btn>
                </div>
              </v-card>
            </v-col>
            <v-col cols="24" sm="12" md="6">
              <v-card class="d-block mx-auto" max-width="400" color="#212121">
                <v-card-title class="justify-center white--text headline">
                  WOLT | LIEFERN LASSEN
                </v-card-title>
                <v-card-subtitle class="text-center white--text overline mt-2">
                  SIE HABEN AUCH DIE MÖGLICHKEIT, GERICHTE VON UNS AUF
                  WOLT ZU BESTELLEN.
                </v-card-subtitle>
                <v-timeline class="white--text" color="white">
                  <v-timeline-item
                    >Auf "Weiter zu WOLT" klicken</v-timeline-item
                  >
                  <v-timeline-item class="text-right">
                    Das Essen bestellen
                  </v-timeline-item>
                  <v-timeline-item>Das Essen liefern lassen</v-timeline-item>
                </v-timeline>
                <div class="m-2 text-center">
                  <v-btn
                    class="mx-auto"
                    color="#009DE0"
                    href="https://wolt.com/de/aut/vienna/restaurant/akaz-asiatisches-restaurant"
                  >
                    <span class="mr-2 white--text">Weiter zu WOLT</span>
                    <!-- <v-icon>mdi-phone</v-icon> -->
                  </v-btn>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </div>
    <div class="d-flex flex-wrap mt-10 d-block mx-auto">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d523.9074565585196!2d16.29707497627329!3d48.19773503136824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476da7f70a84450d%3A0x1ce467f3afde3266!2sAkaz!5e0!3m2!1sde!2sat!4v1635727455261!5m2!1sde!2sat"
        width="100%"
        height="600"
        style="border:5;"
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </div>
  </div>
</template>

<script>
import Backtohome from './Backtohome.vue';
export default {
  components: { Backtohome },
  name: 'Kontakt',
  data() {
    return {};
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-timeline:before {
  background: white;
}
</style>
