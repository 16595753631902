<template>
  <div class="home">
    <v-carousel
      hide-delimiters
      hide-arrows
      :left-control-icon="false"
      :right-control-icon="false"
    >
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        :src="item.src"
        reverse-transition="fade-transition"
        transition="fade-transition"
      ></v-carousel-item>
    </v-carousel>
    <!-- <div class="container">
      <backtohauptspeise></backtohauptspeise>
    </div> -->
    <v-card>
      <v-card-title class="display-3">
        Garnelen
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.speise="{ item }">
          <b>{{ item.speise }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template></v-data-table
      >
    </v-card>
  </div>
</template>

<script>
// import Backtohauptspeise from './Backtohauptspeise.vue';
export default {
  // components: { Backtohauptspeise },
  name: 'Garnelen',
  data() {
    return {
      items: [
        {
          src: require('../assets/images/gebratenegarnelenmithintergrund.jpg'),
        },
      ],
      search: '',
      headers: [
        {
          text: 'Nummer',
          align: 'start',
          sortable: false,
          value: 'nummer',
        },
        { text: 'Speise', value: 'speise' },
        { text: 'Allergene', value: 'allergene' },
        { text: 'Preis', value: 'preis' },
      ],
      desserts: [
        {
          nummer: '161',
          speise: 'Gebackene Garnelen',
          allergene: '(A, B, R, N)',
          preis: '18,20 €',
        },
        {
          nummer: '162',
          speise: 'Gebratene Garnelen mit Paprika(scharf)',
          allergene: '(A, B, F, H, R, N)',
          preis: '16,20 €',
        },
        {
          nummer: '163',
          speise: 'Gebratene Garnelen mit Gemüse',
          allergene: '(A, B, F, H, R, N)',
          preis: '16,20 €',
        },
        {
          nummer: '164',
          speise: 'Gebratene Garnelen nach Sichuan Art (scharf)',
          allergene: '(A, B, F, H, R, N)',
          preis: '16,20 €',
        },
        {
          nummer: '165',
          speise: 'Gebratene Garnelen Chop Suey',
          allergene: '(A, B, F, H, R, N)',
          preis: '16,20 €',
        },
        {
          nummer: '166',
          speise: 'Gebratene Garnelen mit Bambus und Pilzen',
          allergene: '(A, B, F, H, R, N)',
          preis: '16,20 €',
        },
        {
          nummer: '167',
          speise: 'Gebratene Garnelen mit Gemüse und Thai Sauce (scharf)',
          allergene: '(A, B, F, H, R, N, O, G)',
          preis: '16,20 €',
        },
        // {
        //   nummer: '168',
        //   speise: 'Gebratene Garnelen mit Tofu und Gemüse',
        //   allergene: '(A, B, D, F, R, N)',
        //   preis: '15,70 €',
        // },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
