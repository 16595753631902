<template>
  <div class="container">
    <backtohome></backtohome>
    <div class="d-flex flex-wrap">
      <v-tabs centered color="#C5B358">
        <!-- Alkoholfrei -->
        <v-tab href="#alkoholfrei">Alkoholfrei</v-tab>
        <v-tab-item value="alkoholfrei"><alkoholfrei></alkoholfrei></v-tab-item>
        <!-- Warme Getränke -->
        <v-tab href="#warmegetraenke">Warme Getränke</v-tab>
        <v-tab-item value="warmegetraenke"
          ><warmegetraenke></warmegetraenke
        ></v-tab-item>
        <!-- Bier -->
        <v-tab href="#bier">Bier</v-tab>
        <v-tab-item value="bier"><bier></bier></v-tab-item>
        <!-- Weisswein -->
        <v-tab href="#weisswein">Weißwein</v-tab>
        <v-tab-item value="weisswein"><weisswein></weisswein></v-tab-item>
        <!-- Rotwein -->
        <v-tab href="#rotwein">Rotwein</v-tab>
        <v-tab-item value="rotwein"><rotwein></rotwein></v-tab-item>
        <!-- Weingespritzt -->
        <v-tab href="#weingespritzt">Weingespritzt</v-tab>
        <v-tab-item value="weingespritzt"
          ><weingespritzt></weingespritzt
        ></v-tab-item>
        <!-- Asiatischer Wein -->
        <v-tab href="#asiatischerwein">Asiatischer Wein</v-tab>
        <v-tab-item value="asiatischerwein"
          ><asiatischerwein></asiatischerwein
        ></v-tab-item>
        <!-- Aperitifs -->
        <v-tab href="#aperitifs">Aperitifs</v-tab>
        <v-tab-item value="aperitifs"
          ><aperitifs></aperitifs></v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import Backtohome from '../views/Backtohome.vue';
import Alkoholfrei from './Alkoholfrei.vue';
import Warmegetraenke from './Warmegetraenke.vue';
import Bier from '../views/Bier.vue';
import Weisswein from './Weisswein.vue';
import Rotwein from '../views/Rotwein.vue';
import Weingespritzt from '../views/Weingespritzt.vue';
import Asiatischerwein from '../views/Asiatischerwein.vue';
import Aperitifs from '../views/Aperitifs.vue';
// import  from '../views/.vue';

export default {
  components: {
    Backtohome,
    Alkoholfrei,
    Warmegetraenke,
    Bier,
    Weisswein,
    Rotwein,
    Weingespritzt,
    Asiatischerwein,
    Aperitifs
  },
  name:'Getraenkekarte',
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>
<style lang="scss" scoped></style>
