<template>
  <div class="d-flex flex-wrap mt-2 container">
    <div class="container mb-2">
      <v-divider class="mx-4" color="black"></v-divider>
      <h1 class="text-center display-3 font-weight-light mt-2 mb-2">
        Speisekarte
      </h1>
      <v-divider class="mx-4 mb-4" color="black"></v-divider>
      <backtospeisekarte></backtospeisekarte>
    </div>
    <div class="container">
      <v-expansion-panels focusable popout>
        <v-expansion-panel>
          <v-expansion-panel-header
            color="#F5F5F5"
            class="font-weight-light display-1"
            >Neue Gerichte</v-expansion-panel-header
          >
          <v-expansion-panel-content class="mt-6">
            <v-row>
              <v-col cols="12" sm="6" md="4">
        <v-card
          class="mx-auto"
          max-width="344"
          style="background-color: #212121;"
        >
          <v-carousel
            hide-delimiters
            cycle
            max-height="20%"
            background-size="cover"
          >
            <v-carousel-item
              v-for="(item, i) in sushitorte"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
          <v-alert
            dense
            max-width="50%"
            color="green"
            class="text-center mx-auto mt-4"
          >
            NEUHEIT
          </v-alert>
          <v-card-title
            class="justify-center white--text font-weight-light"
            style="background-color: #212121; padding:0;"
          >
            Sushi Torte
          </v-card-title>
          <v-card-subtitle
            class="text-center white--text font-weight-light mt-1"
            style="background-color: #212121;"
          >
            (1 Stk.)<br />
            12,90 €
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card
          class="mx-auto"
          max-width="344"
          style="background-color: #212121;"
        >
          <v-carousel
            hide-delimiters
            cycle
            max-height="20%"
            background-size="cover"
          >
            <v-carousel-item
              v-for="(item, i) in sushidonuts"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
          <v-alert
            dense
            max-width="50%"
            color="green"
            class="text-center mx-auto mt-4"
          >
            NEUHEIT
          </v-alert>
          <v-card-title
            class="justify-center white--text font-weight-light"
            style="background-color: #212121; padding:0;"
          >
            Sushi Donuts
          </v-card-title>
          <v-card-subtitle
            class="text-center white--text font-weight-light mt-1"
            style="background-color: #212121;"
          >
            (3 Stk.)<br />
            12,50 €
          </v-card-subtitle>
        </v-card>
      </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-carousel
                    hide-delimiters
                    cycle
                    max-height="20%"
                    background-size="cover"
                  >
                    <v-carousel-item
                      v-for="(item, i) in drachenmaki"
                      :key="i"
                      :src="item.src"
                    ></v-carousel-item>
                  </v-carousel>
                  <v-alert
                    dense
                    max-width="50%"
                    color="green"
                    class="text-center mx-auto mt-4"
                  >
                    NEUHEIT
                  </v-alert>
                  <v-card-title
                    class="justify-center white--text font-weight-light"
                    style="background-color: #212121; padding:0;"
                  >
                    Drachen Maki
                  </v-card-title>
                  <v-card-subtitle
                    class="text-center white--text font-weight-light mt-1"
                    style="background-color: #212121;"
                  >
                    (8 Stk.)<br />
                    13,90 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-carousel
                    hide-delimiters
                    cycle
                    max-height="20%"
                    background-size="cover"
                  >
                    <v-carousel-item
                      v-for="(item, i) in items"
                      :key="i"
                      :src="item.src"
                    ></v-carousel-item>
                  </v-carousel>
                  <!-- <v-img src="../assets/images/avocadolachskugelcutted.png" height="200px"></v-img> -->
                  <v-alert
                    dense
                    max-width="50%"
                    color="green"
                    class="text-center mx-auto mt-4"
                  >
                    NEUHEIT
                  </v-alert>
                  <v-card-title
                    class="justify-center white--text font-weight-light"
                    style="background-color: #212121; padding-top:0;"
                  >
                    Avocado Lachs Kugeln
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-light"
                    style="background-color: #212121;"
                  >
                    (3 Stk.)<br />
                    13,50 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-carousel
                    hide-delimiters
                    cycle
                    max-height="20%"
                    background-size="cover"
                  >
                    <v-carousel-item
                      v-for="(item, i) in lachsrollen"
                      :key="i"
                      :src="item.src"
                    ></v-carousel-item>
                  </v-carousel>
                  <!-- <v-img src="../assets/images/lachsrollencutted.png" height="200px"></v-img> -->
                  <v-alert
                    dense
                    max-width="50%"
                    color="green"
                    class="text-center mx-auto mt-4"
                  >
                    NEUHEIT
                  </v-alert>
                  <v-card-title
                    class="justify-center white--text font-weight-light"
                    style="background-color: #212121; padding-top:0;"
                  >
                    Lachs Sushi Rollen
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-light"
                    style="background-color: #212121;"
                  >
                    (10 Stk.)<br />
                    11,90 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-carousel
                    hide-delimiters
                    cycle
                    max-height="20%"
                    background-size="cover"
                  >
                    <v-carousel-item
                      v-for="(item, i) in tempuracalifornia"
                      :key="i"
                      :src="item.src"
                    ></v-carousel-item>
                  </v-carousel>
                  <!-- <v-img src="../assets/images/tempuracaliforniacutted.png" height="200px"></v-img> -->
                  <v-alert
                    dense
                    max-width="50%"
                    color="green"
                    class="text-center mx-auto mt-4"
                  >
                    NEUHEIT
                  </v-alert>
                  <v-card-title
                    class="justify-center white--text font-weight-light"
                    style="background-color: #212121; padding-top:0;"
                  >
                    Tempura California Maki
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-light"
                    style="background-color: #212121;"
                  >
                    (12 Stk.)<br />
                    9,70 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- Vorspeisen -->
      <v-expansion-panels focusable popout class="mt-4">
        <v-expansion-panel>
          <v-expansion-panel-header
            color="#F5F5F5"
            class="font-weight-light display-1"
            >Vorspeise</v-expansion-panel-header
          >
          <v-expansion-panel-content class="mt-6">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-carousel
                    hide-delimiters
                    cycle
                    max-height="20%"
                    background-size="cover"
                  >
                    <v-carousel-item
                      v-for="(item, i) in gebackenegarnelen"
                      :key="i"
                      :src="item.src"
                    ></v-carousel-item>
                  </v-carousel>
                  <v-card-title
                    class="justify-center white--text font-weight-thin "
                    style="background-color: #212121;"
                  >
                    Gebackene Garnelen
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin "
                    style="background-color: #212121;"
                  >
                    (6 Stk.)<br />
                    5,90 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-carousel
                    hide-delimiters
                    cycle
                    max-height="20%"
                    background-size="cover"
                  >
                    <v-carousel-item
                      v-for="(item, i) in minifruehlingsrolle"
                      :key="i"
                      :src="item.src"
                    ></v-carousel-item>
                  </v-carousel>

                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Mini Frühlingsrolle
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    (5 Stk.)<br />
                    3,60 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-carousel
                    hide-delimiters
                    cycle
                    max-height="20%"
                    background-size="cover"
                  >
                    <v-carousel-item
                      v-for="(item, i) in fleischtaschennachshanghaiart"
                      :key="i"
                      :src="item.src"
                    ></v-carousel-item>
                  </v-carousel>
                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Fleischtaschen <br />
                    nach Shanghai-Art
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    (4 Stk.)<br />
                    4,70 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-carousel
                    hide-delimiters
                    cycle
                    max-height="20%"
                    background-size="cover"
                  >
                    <v-carousel-item
                      v-for="(item, i) in reismehltaschengarnelenbaerlauch"
                      :key="i"
                      :src="item.src"
                    ></v-carousel-item>
                  </v-carousel>
                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Reismehltaschen mit <br />
                    Garnelen und Bärlauch
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    (4 Stk.)<br />
                    4,70 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-carousel
                    hide-delimiters
                    cycle
                    max-height="20%"
                    background-size="cover"
                  >
                    <v-carousel-item
                      v-for="(item, i) in reismehltaschengarnelen"
                      :key="i"
                      :src="item.src"
                    ></v-carousel-item>
                  </v-carousel>
                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Reismehltaschen mit Garnelen
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    (4 Stk.)<br />
                    4,70 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-carousel
                    hide-delimiters
                    cycle
                    max-height="20%"
                    background-size="cover"
                  >
                    <v-carousel-item
                      v-for="(item, i) in teigtaschenschweinefleisch"
                      :key="i"
                      :src="item.src"
                    ></v-carousel-item>
                  </v-carousel>
                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Teigtaschen mit Schweinefleisch
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    (4 Stk.)<br />
                    4,70 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-carousel
                    hide-delimiters
                    cycle
                    max-height="20%"
                    background-size="cover"
                  >
                    <v-carousel-item
                      v-for="(item, i) in knoedelmitschweinefleisch"
                      :key="i"
                      :src="item.src"
                    ></v-carousel-item>
                  </v-carousel>
                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Knödeln mit Schweinefleisch
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    (4 Stk.)<br />
                    4,70 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- Makis -->
      <v-expansion-panels focusable popout class="mt-4">
        <v-expansion-panel>
          <v-expansion-panel-header
            color="#F5F5F5"
            class="font-weight-light display-1"
            >Makis</v-expansion-panel-header
          >
          <v-expansion-panel-content class="mt-6">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-img
                    src="../assets/images/tempuracaliforniacutted.png"
                    height="200px"
                  ></v-img>

                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Tempura California Maki
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    (12 Stk.)<br />
                    9,70 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-img
                    src="../assets/images/rainbowmakiohnehintergrund.png"
                    height="200px"
                  ></v-img>

                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Rainbow Makis
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    (12 Stk.)<br />
                    8,60 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-img
                    src="../assets/images/makiimsetohnehintergrund.png"
                    height="200px"
                  ></v-img>

                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Maki im Set
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    (18 Stk.)<br />
                    8,00 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-img
                    src="../assets/images/makinormalohnehintergrund.png"
                    height="200px"
                  ></v-img>

                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Lachs Makis
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    (6 Stk.)<br />
                    3,70 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- Sushi -->
      <v-expansion-panels focusable popout class="mt-4">
        <v-expansion-panel>
          <v-expansion-panel-header
            color="#F5F5F5"
            class="font-weight-light display-1"
            >Sushi</v-expansion-panel-header
          >
          <v-expansion-panel-content class="mt-6">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-img
                    src="../assets/images/lachssushirollenohnehintergrund.png"
                    height="200px"
                  ></v-img>

                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Lachs Sushi Rollen
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    (10 Stk.)<br />
                    11,90 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-img
                    src="../assets/images/sushiohnehintergrund.png"
                    height="200px"
                  ></v-img>

                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Sushi Set groß
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    (11 Stk. und 3 Makis)<br />
                    8,90 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-img
                    src="../assets/images/avocadolachskugelcutted.png"
                    height="200px"
                  ></v-img>

                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Avocado Lachs Kugeln
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    (3 Stk.)<br />
                    13,50 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- Warme Hauptspeisen -->
      <v-expansion-panels focusable popout class="mt-4">
        <v-expansion-panel>
          <v-expansion-panel-header
            color="#F5F5F5"
            class="font-weight-light display-1"
            >Warme Hauptspeisen</v-expansion-panel-header
          >
          <v-expansion-panel-content class="mt-6">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-img
                    src="../assets/images/knusprigeenteohnehintergrund.png"
                    height="200px"
                  ></v-img>

                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Knusprige Ente mit Gemüse
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    10,50 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-img
                    src="../assets/images/garnelen-cutted.png"
                    height="200px"
                  ></v-img>

                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Gebratene Garnelen mit Gemüse
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    14,40 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-img
                    src="../assets/images/gebrateneshuehnerfleischohnehintergrund.png"
                    height="200px"
                  ></v-img>

                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Gebratenes Hühnerfleisch <br />
                    mit Gemüse
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    8,60 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-img
                    src="../assets/images/gebratenesrindfleischohnehintergrund.png"
                    height="200px"
                  ></v-img>

                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Gebratenes Rindfleisch <br />
                    mit Zwiebeln
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    9,70 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-img
                    src="../assets/images/lachssteakohnehintergrund.png"
                    height="200px"
                  ></v-img>

                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Gegrilltes Lachssteak <br />
                    mit Gemüse
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    10,50 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-img
                    src="../assets/images/ramenohnehintergrund.png"
                    height="200px"
                  ></v-img>

                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Nudelsuppe <br />
                    mit Meeresfrüchten
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    10,20 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-img
                    src="../assets/images/gebratenenudelnohnehintergrund.png"
                    height="200px"
                  ></v-img>

                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Gebratene Nudeln mit Gemüse
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    7,00 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-img
                    src="../assets/images/spezialitaetentellerohnehintergrund.png"
                    height="200px"
                  ></v-img>

                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Spezialitätenteller mit <br />
                    Rindfleisch, Hühnerspieß <br />und Ente
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    10,30 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-img
                    src="../assets/images/gebratenerreisohnehintergrund.png"
                    height="200px"
                  ></v-img>

                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Gebratener Reis <br />mit Gemüse
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    7,00 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-img
                    src="../assets/images/rindfleischganbianohnehintergrund.png"
                    height="200px"
                  ></v-img>

                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Rindfleisch Ganbian
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    (scharf)<br />
                    10,30 €
                  </v-card-subtitle>
                </v-card>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-img
                    src="../assets/images/gebrateneudonohnehintergrund.png"
                    height="200px"
                  ></v-img>

                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Gebratene Udon mit <br />Hühnerfleisch
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    7,40 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- Suppen -->
      <v-expansion-panels focusable popout class="mt-4">
        <v-expansion-panel>
          <v-expansion-panel-header
            color="#F5F5F5"
            class="font-weight-light display-1"
            >Suppen</v-expansion-panel-header
          >
          <v-expansion-panel-content class="mt-6">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-img
                    src="../assets/images/pikantesuppeohnehintergrund.png"
                    height="200px"
                  ></v-img>

                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Pikante Suppe
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    3,20 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-img
                    src="../assets/images/misosuppeohnehintergrund.png"
                    height="200px"
                  ></v-img>

                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Miso Suppe
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    2,90 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- Sashimi -->
      <v-expansion-panels focusable popout class="mt-4">
        <v-expansion-panel>
          <v-expansion-panel-header
            color="#F5F5F5"
            class="font-weight-light display-1"
            >Sashimi</v-expansion-panel-header
          >
          <v-expansion-panel-content class="mt-6">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-img
                    src="../assets/images/sashimiohnehintergrund.png"
                    height="200px"
                  ></v-img>

                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Sashimi Set groß
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    19,50 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- Gemischter Teller -->
      <v-expansion-panels focusable popout class="mt-4">
        <v-expansion-panel>
          <v-expansion-panel-header
            color="#F5F5F5"
            class="font-weight-light display-1"
            >Gemischter Teller</v-expansion-panel-header
          >
          <v-expansion-panel-content class="mt-6">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  style="background-color: #212121;"
                >
                  <v-img
                    src="../assets/images/gemischtertellerohnehintergrund.png"
                    height="200px"
                  ></v-img>

                  <v-card-title
                    class="justify-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    Sushi-Sashimi Teller
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                    style="background-color: #212121;"
                  >
                    16,90 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- Temakis -->
      <v-expansion-panels focusable popout class="mt-4">
        <v-expansion-panel>
          <v-expansion-panel-header
            color="#F5F5F5"
            class="font-weight-light display-1"
            >Temakis</v-expansion-panel-header
          >
          <v-expansion-panel-content class="mt-6">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto white--text"
                  max-width="344"
                  to="/temakis"
                  style="background-color: #212121;"
                >
                  <v-img
                    src="../assets/images/temakiohnehintergrund.png"
                    height="200px"
                  ></v-img>

                  <v-card-title class="justify-center font-weight-thin">
                    Lachs Temaki
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                  >
                    3,80 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- Salate -->
      <v-expansion-panels focusable popout class="mt-4">
        <v-expansion-panel>
          <v-expansion-panel-header
            color="#F5F5F5"
            class="font-weight-light display-1"
            >Salate</v-expansion-panel-header
          >
          <v-expansion-panel-content class="mt-6">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto white--text"
                  max-width="344"
                  to="/temakis"
                  style="background-color: #212121;"
                >
                  <v-img
                    src="../assets/images/tofusalat-cutted.png"
                    height="200px"
                  ></v-img>

                  <v-card-title class="justify-center font-weight-thin">
                    Tofu Salat
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                  >
                    3,80 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto white--text"
                  max-width="344"
                  to="/temakis"
                  style="background-color: #212121;"
                >
                  <v-img
                    src="../assets/images/pikantesalat-cutted.png"
                    height="200px"
                  ></v-img>

                  <v-card-title class="justify-center font-weight-thin">
                    Tofu Salat
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                  >
                    3,80 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto white--text"
                  max-width="344"
                  to="/temakis"
                  style="background-color: #212121;"
                >
                  <v-img
                    src="../assets/images/ingwersalatohnehintergrund.png"
                    height="200px"
                  ></v-img>

                  <v-card-title class="justify-center font-weight-thin">
                    Ingwer Salat
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                  >
                    3,00 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- Nachspeise -->
      <v-expansion-panels focusable popout class="mt-4">
        <v-expansion-panel>
          <v-expansion-panel-header
            color="#F5F5F5"
            class="font-weight-light display-1"
            >Nachspeise</v-expansion-panel-header
          >
          <v-expansion-panel-content class="mt-6">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-card
                  class="mx-auto white--text"
                  max-width="344"
                  to="/temakis"
                  style="background-color: #212121;"
                >
                  <v-img
                    src="../assets/images/gebackenebananeohnehintergrund.png"
                    height="200px"
                  ></v-img>

                  <v-card-title class="justify-center font-weight-thin">
                    Gebackene Banane
                  </v-card-title>

                  <v-card-subtitle
                    class="text-center white--text font-weight-thin"
                  >
                    4,00 €
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import Backtospeisekarte from './Backtospeisekarte.vue';
export default {
  components: {
    Backtospeisekarte,
  },
  data() {
    return {
      name: 'Galerieeins',
      panel: [],
      item: 5,
            sushitorte: [
        {
          src: require('../assets/images/sushitortefrontal.png'),
        },
        {
          src: require('../assets/images/sushitorteoben.png'),
        },
      ],
      sushidonuts: [
        {
          src: require('../assets/images/sushidonutsfrontal.png'),
        },
        {
          src: require('../assets/images/sushidonutsoben.png'),
        },
      ],
      drachenmaki: [
        {
          src: require('../assets/images/drachenmakifrontalweiss.jpg'),
        },
        {
          src: require('../assets/images/drachenmakivogelperspektive.jpg'),
        },

        {
          src: require('../assets/images/drachenmakiganzweiss.jpg'),
        },
      ],
      items: [
        {
          src: require('../assets/images/avocadolachskugelartshotfrontal.jpg'),
        },
        {
          src: require('../assets/images/avocadolachskugelartshot.jpg'),
        },
      ],
      lachsrollen: [
        {
          src: require('../assets/images/lachsrollenfrontalshow.jpg'),
        },
        {
          src: require('../assets/images/lachsrollenvonoben.jpg'),
        },
      ],
      tempuracalifornia: [
        {
          src: require('../assets/images/tempuracaliforniagalerie.jpg'),
        },
        {
          src: require('../assets/images/tempuracaliforniavonoben.jpg'),
        },
      ],
      gebackenegarnelen: [
        {
          src: require('../assets/images/gebackenegarnelenmithintergrund.jpg'),
        },
      ],
      minifruehlingsrolle: [
        {
          src: require('../assets/images/minifruehlingsrollemithintergrund.jpg'),
        },
      ],
      fleischtaschennachshanghaiart: [
        {
          src: require('../assets/images/dimsum-uncutted-background.png'),
        },
      ],
      reismehltaschengarnelen: [
        {
          src: require('../assets/images/reismehltaschengarnelen.jpg'),
        },
      ],
      reismehltaschengarnelenbaerlauch: [
        {
          src: require('../assets/images/reismehltaschengarnelenbaerlauch.jpg'),
        },
      ],
      teigtaschenschweinefleisch: [
        {
          src: require('../assets/images/teigtaschenschweinefleisch.jpg'),
        },
      ],
      knoedelmitschweinefleisch: [
        {
          src: require('../assets/images/gedaempfteknoedelnmitschweinefleisch.jpg'),
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
