var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('v-carousel',{attrs:{"hide-delimiters":"","cycle":""}},_vm._l((_vm.items),function(item,i){return _c('v-carousel-item',{key:i,attrs:{"src":item.src}},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","justify":"center"}},[_c('div',{staticClass:"text-h1 white--text"},[_vm._v(" "+_vm._s(item.title)+" ")])])],1)}),1),_c('v-card',[_c('v-card-title',{staticClass:"display-3"},[_vm._v(" Maki "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.desserts,"search":_vm.search,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.speise",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.speise))])]}},{key:"item.anzahl1",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.anzahl1))])]}},{key:"item.anzahl3",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.anzahl3))])]}},{key:"item.anzahl4",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.anzahl4))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }