<template>
  <div class="home">
    <v-carousel hide-delimiters cycle>
      <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src">
        <v-row class="fill-height" align="center" justify="center">
          <div class="text-h1 white--text">
            {{ item.title }}
          </div>
        </v-row></v-carousel-item
      >
    </v-carousel>
    <v-card>
      <v-card-title class="display-3">
        Alkoholfrei
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.alkoholfrei="{ item }">
          <b>{{ item.alkoholfrei }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template></v-data-table
      >
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Alkoholfrei',
  data() {
    return {
      items: [
        {
          src: require('../assets/images/getraenkealkoholfrei.jpg'),
          title: '',
        },
      ],
      search: '',
      headers: [
        { text: 'Getränk', value: 'alkoholfrei' },
        { text: 'Menge', value: 'menge' },
        { text: 'Preis', value: 'preis' },
      ],
      desserts: [
        {
          alkoholfrei: 'Cola Zero',
          menge: '0,33 l Fl.',
          preis: '3,80 €',
        },
        {
          alkoholfrei: 'Coca Cola',
          menge: '0,33 l Fl.',
          preis: '3,80 €',
        },
        {
          alkoholfrei: 'Sprite',
          menge: '0,33 l Fl.',
          preis: '3,80 €',
        },
        {
          alkoholfrei: 'Fanta',
          menge: '0,33 l Fl.',
          preis: '3,80 €',
        },
        {
          alkoholfrei: 'Almdudler',
          menge: '0,33 l Fl.',
          preis: '3,80 €',
        },
        {
          alkoholfrei: 'Eistee Zitrone',
          menge: '0,25 l',
          preis: '3,00 €',
        },
        {
          alkoholfrei: 'Apfelsaft',
          menge: '0,25 l',
          preis: '3,00 €',
        },
        // {
        //   alkoholfrei: 'Orangensaft',
        //   menge: '0,25 l',
        //   preis: '3,00 €',
        // },
        // {
        //   alkoholfrei: 'Tonic',
        //   menge: '0,20 l Fl.',
        //   preis: '3,80 €',
        // },
        // {
        //   alkoholfrei: 'Red Bull',
        //   menge: '0,25 l Dose',
        //   preis: '4,50 €',
        // },
        {
          alkoholfrei: 'Mineral Wasser prickelnd',
          menge: '0,25 l Fl.',
          preis: '3,40 €',
        },
        {
          alkoholfrei: 'Mineral Wasser still',
          menge: '0,25 l Fl.',
          preis: '3,40 €',
        },
        {
          alkoholfrei: 'Lycheesaft',
          menge: '0,25 l Dose',
          preis: '3,90 €',
        },
        {
          alkoholfrei: 'Lycheesaft mit Wasser',
          menge: '0,50 l',
          preis: '4,10 €',
        },
        {
          alkoholfrei: 'Mangosaft',
          menge: '0,25 l Dose',
          preis: '3,90 €',
        },
        {
          alkoholfrei: 'Mangosaft mit Wasser',
          menge: '0,50 l',
          preis: '4,10 €',
        },
        // {
        //   alkoholfrei: 'Kokossaft (G)',
        //   menge: '0,25 l Dose',
        //   preis: '3,60 €',
        // },
        // {
        //   alkoholfrei: 'Calpis Wasser (G)',
        //   menge: '0,25 l',
        //   preis: '2,90 €',
        // },
        // {
        //   alkoholfrei: 'Calpis Wasser (G)',
        //   menge: '0,50 l',
        //   preis: '4,10 €',
        // },
        // {
        //   alkoholfrei: 'Calpis gespritzt (G)',
        //   menge: '0,25 l',
        //   preis: '3,60 €',
        // },
        // {
        //   alkoholfrei: 'Calpis gespritzt (G)',
        //   menge: '0,50 l',
        //   preis: '5,10 €',
        // },
        {
          alkoholfrei: 'Soda',
          menge: '0,25 l',
          preis: '2,60 €',
        },
        {
          alkoholfrei: 'Soda',
          menge: '0,50 l',
          preis: '3,90 €',
        },
        {
          alkoholfrei: 'Soda Zitrone',
          menge: '0,25 l',
          preis: '3,00 €',
        },
        {
          alkoholfrei: 'Soda Zitrone',
          menge: '0,50 l',
          preis: '4,20 €',
        },
        {
          alkoholfrei: 'Soda Himbeere',
          menge: '0,25 l',
          preis: '3,00€',
        },
        {
          alkoholfrei: 'Soda Himbeere',
          menge: '0,50 l',
          preis: '4,50 €',
        },
        // {
        //   alkoholfrei: 'Cola',
        //   menge: '0,50 l',
        //   preis: '5,00 €',
        // },
        // {
        //   alkoholfrei: 'Sprite',
        //   menge: '0,50 l',
        //   preis: '4,80 €',
        // },
        // {
        //   alkoholfrei: 'Fanta',
        //   menge: '0,50 l',
        //   preis: '4,80 €',
        // },
        // {
        //   alkoholfrei: 'Almdudler',
        //   menge: '0,50 l',
        //   preis: '5,00 €',
        // },
        {
          alkoholfrei: 'Eistee',
          menge: '0,50 l',
          preis: '5,00 €',
        },
        {
          alkoholfrei: 'Apfelsaft',
          menge: '0,50 l',
          preis: '5,00 €',
        },
        {
          alkoholfrei: 'Almdudler gespritzt',
          menge: '0,50 l',
          preis: '4,50 €',
        },
        {
          alkoholfrei: 'Apfelsaft gespritzt',
          menge: '0,50 l',
          preis: '4,50 €',
        },
        // {
        //   alkoholfrei: 'Orangensaft gespritzt',
        //   menge: '0,50 l',
        //   preis: '4,30 €',
        // },
        {
          alkoholfrei: 'Lycheesaft gespritzt',
          menge: '0,50 l',
          preis: '4,50 €',
        },
        {
          alkoholfrei: 'Mangosaft gespritzt',
          menge: '0,50 l',
          preis: '4,50 €',
        },
        {
          alkoholfrei: 'Glas Wiener Wasser',
          menge: '0,50 l',
          preis: '0,70 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
