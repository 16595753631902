<template>
  <div class="home">
    <v-carousel
      hide-delimiters
      hide-arrows
      :left-control-icon="false"
      :right-control-icon="false"
    >
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        :src="item.src"
        reverse-transition="fade-transition"
        transition="fade-transition"
      ></v-carousel-item>
    </v-carousel>
    <!-- <div class="container">
      <backtohauptspeise></backtohauptspeise>
    </div> -->
    <v-card>
      <v-card-title class="display-3 mx-auto d-block d-flex">
        Spezialitäten
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.speise="{ item }">
          <b>{{ item.speise }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template></v-data-table
      >
    </v-card>
  </div>
</template>

<script>
// import Backtohauptspeise from './Backtohauptspeise.vue';
export default {
  // components: { Backtohauptspeise },
  name: 'Spezialitaeten',
  data() {
    return {
      items: [
        {
          src: require('../assets/images/spezialitaetentellermithintergrund.jpg'),
        },
      ],
      search: '',
      headers: [
        {
          text: 'Nummer',
          align: 'start',
          sortable: false,
          value: 'nummer',
        },
        { text: 'Speise', value: 'speise' },
        { text: 'Anzahl', value: 'anzahl' },
        { text: 'Allergene', value: 'allergene' },
        { text: 'Preis', value: 'preis' },
      ],
      desserts: [
        {
          nummer: 'Z1',
          speise: 'Acht Schätze (scharf)',
          anzahl: '',
          allergene: '(A, F)',
          preis: '12,40 €',
        },
        {
          nummer: 'Z2',
          speise: 'Acht Schätze mit knuspriger Ente (scharf)',
          anzahl: '',
          allergene: '(A, F)',
          preis: '18,00 €',
        },
        {
          nummer: 'Z3',
          speise: 'Gegrilltes Seafood mit Gemüse',
          anzahl: '',
          allergene: '(A, D, F, R)',
          preis: '13,40 €',
        },
        {
          nummer: 'Z4',
          speise: 'Spezialitätenteller mit Rindfleisch, Hühnerspieß und Ente',
          anzahl: '',
          allergene: '(A,F)',
          preis: '13,00 €',
        },
        // {
        //   nummer: 'Z5',
        //   speise: 'kleine Tintenfische mit Tong Yam Gong Sauce (scharf)',
        //   anzahl: '',
        //   allergene: '(A, F)',
        //   preis: '11,90 €',
        // },
        {
          nummer: 'Z6',
          speise: 'Gegrillte Muscheln mit Knoblach Sauce',
          anzahl: '',
          allergene: '(A, D, F, R)',
          preis: '12,40 €',
        },
        // {
        //   nummer: 'Z7',
        //   speise: 'Akamedon (knuspriger Ente mit Teriyaki Sauce auf Reis',
        //   anzahl: '',
        //   allergene: '(A, F)',
        //   preis: '10,50 €',
        // },
        // {
        //   nummer: 'Z8',
        //   speise: 'Sakedon (Lachs mit Teriyaki Sauce auf Reis)',
        //   anzahl: '',
        //   allergene: '(A, F)',
        //   preis: '10,50 €',
        // },
        // {
        //   nummer: 'Z9',
        //   speise: 'Magurodon (Thunfisch mit Teriyaki Sauce auf Reis',
        //   anzahl: '',
        //   allergene: '(A, F)',
        //   preis: '10,90 €',
        // },
        // {
        //   nummer: 'Z10',
        //   speise: 'Toridon (Hühnerbrust gegrillt mit Teriyaki Sauce)',
        //   anzahl: '',
        //   allergene: '(A, F)',
        //   preis: '8,90 €',
        // },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
