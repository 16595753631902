<template>
  <div class="home">
    <div class="d-flex flex-wrap mt-10">
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            to="/weisswein"
            style="background-color: #212121;"
          >
            <v-img
              src="../assets/images/weissweinglasoben.jpg"
              height="200px"
            ></v-img>

            <v-card-title class="justify-center font-weight-thin">
              Weißwein
            </v-card-title>

            <!-- <v-card-subtitle>
              1,000 miles of wonder
            </v-card-subtitle> -->
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            to="/rotwein"
            style="background-color: #212121;"
          >
            <v-img src="../assets/images/rotweinpur.jpg" height="200px"></v-img>

            <v-card-title class="justify-center font-weight-thin">
              Rotwein
            </v-card-title>

            <!-- <v-card-subtitle>
              1,000 miles of wonder
            </v-card-subtitle> -->
          </v-card> </v-col
        ><v-col cols="12" sm="6" md="4">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            to="/weingespritzt"
            style="background-color: #212121;"
          >
            <v-img
              src="../assets/images/weingespritzt.jpg"
              height="200px"
            ></v-img>

            <v-card-title class="justify-center font-weight-thin">
              Wein gespritzt
            </v-card-title>

            <!-- <v-card-subtitle>
              1,000 miles of wonder
            </v-card-subtitle> -->
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            to="/asiatischerwein"
            style="background-color: #212121;"
          >
            <v-img
              src="../assets/images/pflaumenwein.jpg"
              height="200px"
            ></v-img>

            <v-card-title class="justify-center font-weight-thin">
              Asiatischer Wein
            </v-card-title>

            <!-- <v-card-subtitle>
              1,000 miles of wonder
            </v-card-subtitle> -->
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Offeneweine',
  data() {
    return {
      items: [
        {
          src: require('../assets/images/dimsum-uncutted-background.png'),
          title: 'VORSPEISE',
        },
        {
          src: require('../assets/images/fleischtaschen.jpg'),
          title: 'VORSPEISE',
        },
        {
          src: require('../assets/images/rainbowmaki.jpg'),
          title: 'VORSPEISE',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
