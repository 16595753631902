<template>
  <div class="home">
    <v-carousel hide-delimiters cycle>
      <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src">
        <v-row class="fill-height" align="center" justify="center">
          <div class="text-h1 white--text">
            {{ item.title }}
          </div>
        </v-row></v-carousel-item
      >
    </v-carousel>
    <v-card>
      <v-card-title class="display-3">
        Aperitifs
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        :items-per-page="-1"
        ><template v-slot:item.aperitif="{ item }">
          <b>{{ item.aperitif }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template></v-data-table
      >
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Aperitifs',
  data() {
    return {
      items: [
        {
          src: require('../assets/images/camparisodanahaufnahme.jpg'),
          title: '',
        },
        {
          src: require('../assets/images/camparisodaoben.jpg'),
          title: '',
        },
        {
          src: require('../assets/images/camparisoda.jpg'),
          title: '',
        },
      ],
      search: '',
      headers: [
        { text: 'Aperitifs', value: 'aperitif' },
        { text: 'Menge', value: 'menge' },
        { text: 'Preis', value: 'preis' },
      ],
      desserts: [
        // {
        //   aperitif: 'Martini Bianco',
        //   menge: '2 cl',
        //   preis: '3,50 €',
        // },
        // {
        //   aperitif: 'Whisky',
        //   menge: '2 cl',
        //   preis: '3,90 €',
        // },
        // {
        //   aperitif: 'Fernet',
        //   menge: '2 cl',
        //   preis: '3,50 €',
        // },
        // {
        //   aperitif: 'Wodka Orange',
        //   menge: '2 cl',
        //   preis: '4,50 €',
        // },
        // {
        //   aperitif: 'Wodka',
        //   menge: '2 cl',
        //   preis: '3,90 €',
        // },
        // {
        //   aperitif: 'Bacardi Cola',
        //   menge: '2 cl',
        //   preis: '4,50 €',
        // },
        // {
        //   aperitif: 'Whisky Cola',
        //   menge: '2 cl',
        //   preis: '4,50 €',
        // },
        // {
        //   aperitif: 'Ginseng Schnaps',
        //   menge: '4 cl',
        //   preis: '4,50 €',
        // },
        {
          aperitif: 'Rose Schnaps',
          menge: '4 cl',
          preis: '4,90 €',
        },
        {
          aperitif: 'Bambus Schnaps',
          menge: '4 cl',
          preis: '4,90 €',
        },
        // {
        //   aperitif: 'Gin Tonic',
        //   menge: '4 cl',
        //   preis: '4,50 €',
        // },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
