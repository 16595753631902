<template>
  <div class="home">
    <div class="container">
      <v-divider class="mx-4 mt-4" color="black"></v-divider>
      <h1 class="text-center display-2 font-weight-light mt-4">HAUPTSPEISEN</h1>
      <v-divider class="mx-4 mt-4" color="black"></v-divider>
      <backtospeisekarte class="mt-2"></backtospeisekarte>
    </div>
    <div class="d-flex flex-wrap mt-4">
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            to="/spezialitaeten"
            style="background-color: #212121;"
          >
            <v-img
              src="../assets/images/spezialitaetentellerohnehintergrund.png"
              height="200px"
            ></v-img>

            <v-card-title class="justify-center font-weight-light">
              Spezialitäten
            </v-card-title>

            <!-- <v-card-subtitle>
              1,000 miles of wonder
            </v-card-subtitle> -->
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            to="/gemuese"
            style="background-color: #212121;"
          >
            <v-img
              src="../assets/images/gebratenerreisohnehintergrund.png"
              height="200px"
            ></v-img>

            <v-card-title class="justify-center font-weight-light">
              Gemüse
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            to="/huehnerfleisch"
            style="background-color: #212121;"
          >
            <v-img
              src="../assets/images/gebrateneshuehnerfleischohnehintergrund.png"
              height="200px"
            ></v-img>

            <v-card-title class="justify-center font-weight-light">
              Hühnerfleisch
            </v-card-title>

            <!-- <v-card-subtitle>
              1,000 miles of wonder
            </v-card-subtitle> -->
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            to="/rindfleisch"
            style="background-color: #212121;"
          >
            <v-img
              src="../assets/images/gebratenesrindfleischohnehintergrund.png"
              height="200px"
            ></v-img>

            <v-card-title class="justify-center font-weight-light">
              Rindfleisch
            </v-card-title>

            <!-- <v-card-subtitle>
              1,000 miles of wonder
            </v-card-subtitle> -->
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            to="/schweinefleisch"
            style="background-color: #212121;"
          >
            <v-img
              src="../assets/images/gebratenesschweinefleischohnehintergrund.png"
              height="200px"
            ></v-img>

            <v-card-title class="justify-center font-weight-light">
              Schweinefleisch
            </v-card-title>

            <!-- <v-card-subtitle>
              1,000 miles of wonder
            </v-card-subtitle> -->
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            to="/knusprigeente"
            style="background-color: #212121;"
          >
            <v-img
              src="../assets/images/knusprigeenteohnehintergrund.png"
              height="200px"
            ></v-img>

            <v-card-title class="justify-center font-weight-light">
              Knusprige Ente
            </v-card-title>

            <!-- <v-card-subtitle>
              1,000 miles of wonder
            </v-card-subtitle> -->
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            to="/fisch"
            style="background-color: #212121;"
          >
            <v-img
              src="../assets/images/lachssteakohnehintergrund.png"
              height="200px"
            ></v-img>

            <v-card-title class="justify-center font-weight-light">
              Fisch
            </v-card-title>

            <!-- <v-card-subtitle>
              1,000 miles of wonder
            </v-card-subtitle> -->
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            to="/garnelen"
            style="background-color: #212121;"
          >
            <v-img
              src="../assets/images/garnelen-cutted.png"
              height="200px"
            ></v-img>

            <v-card-title class="justify-center font-weight-light">
              Garnelen
            </v-card-title>

            <!-- <v-card-subtitle>
              1,000 miles of wonder
            </v-card-subtitle> -->
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            to="/nudeln"
            style="background-color: #212121;"
          >
            <v-img
              src="../assets/images/gebratenenudelnohnehintergrund.png"
              height="200px"
            ></v-img>

            <v-card-title class="justify-center font-weight-light">
              Nudeln
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            to="/udon"
            style="background-color: #212121;"
          >
            <v-img
              src="../assets/images/gebrateneudonohnehintergrund.png"
              height="200px"
            ></v-img>

            <v-card-title class="justify-center font-weight-light">
              Udon
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            to="/reis"
            style="background-color: #212121;"
          >
            <v-img
              src="../assets/images/gebratenerreisohnehintergrund.png"
              height="200px"
            ></v-img>

            <v-card-title class="justify-center font-weight-light">
              Reis
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            to="/bentobox"
            style="background-color: #212121;"
          >
            <v-img
              src="../assets/images/bentoboxohnehintergrund.png"
              height="200px"
            ></v-img>

            <v-card-title class="justify-center font-weight-light">
              Bento Box
            </v-card-title>
            <v-card-subtitle
              class="text-center white--text font-weight-thin font-italic"
            >
              Zu jeder Box gibt es Suppe mit Reis oder Frühlingsrolle mit Reis
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Backtospeisekarte from './Backtospeisekarte.vue';
export default {
  components: { Backtospeisekarte },
  name: 'Hauptspeise',
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
