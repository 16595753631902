<template>
  <div class="home">
    <v-carousel hide-delimiters cycle>
      <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src">
        <v-row class="fill-height" align="center" justify="center">
          <div class="text-h1 white--text">
            {{ item.title }}
          </div>
        </v-row></v-carousel-item
      >
    </v-carousel>
    <v-card>
      <v-card-title class="display-3">
        Bier
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        :items-per-page="-1"
        ><template v-slot:item.bier="{ item }">
          <b>{{ item.bier }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template></v-data-table
      >
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Bier',
  data() {
    return {
      items: [
        {
          src: require('../assets/images/bier.jpg'),
          title: '',
        },
      ],
      search: '',
      headers: [
        { text: 'Bier', value: 'bier' },
        { text: 'Menge', value: 'menge' },
        { text: 'Preis', value: 'preis' },
      ],
      desserts: [
        {
          bier: 'Ottakringer vom Fass',
          menge: '0,30 l',
          preis: '3,90 €',
        },
        {
          bier: 'Ottakringer vom Fass',
          menge: '0,50 l',
          preis: '5,10 €',
        },
        // {
        //   bier: 'Helles Bier',
        //   menge: '0,50 l Fl.',
        //   preis: '4,50 €',
        // },
        {
          bier: 'Dunkles Bier',
          menge: '0,50 l Fl.',
          preis: '4,90 €',
        },
        {
          bier: 'Weizenbier',
          menge: '0,50 l Fl.',
          preis: '4,90 €',
        },
        {
          bier: 'Radler',
          menge: '0,50 l',
          preis: '4,90 €',
        },
        {
          bier: 'Kirin (japanisches Bier)',
          menge: '0,33 l Fl.',
          preis: '4,90 €',
        },
        {
          bier: 'Alkoholfreies Bier',
          menge: '0,50 l Fl.',
          preis: '4,90 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
