<template>
  <div class="home">
    <v-carousel
      hide-delimiters
      cycle
      max-width="100%"
      height="800px"
      background-size="cover"
    >
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        :src="item.src"
      ></v-carousel-item>
    </v-carousel>
    <!-- <v-alert color="orange" type="info" class="text-center"
      >Sehr geehrte Gäste wir haben am Dienstag, den 17.05.2022 erst um 15:00
      geöffnet!</v-alert
    > -->
    <!-- WEIHNACHTSÖFFNUNGSZEITEN -->
    <!-- <v-alert color="#00C853" type="info" class="text-center"
      >Sehr geehrte Kunden und Kundinnen wir haben von
      <b>23.12.2023 bis 26.12.2023</b> und
      <b>von 30.12.2023 bis 01.01.2024</b> geschlossen! <br /><br /><b
        >WIR WÜNSCHEN IHNEN FROHES FEST UND ERHOLSAME FERIEN!</b
      >
      <br /><b
        >WIR FREUEN UNS IM NEUEN JAHR WIEDER FÜR SIE DA SEIN ZU DÜRFEN!</b
      ></v-alert
    > -->
    <div class="container" style="background-color: white">
      <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
      <div class="d-flex flex-wrap mt-10">
        <v-row>
          <!-- <v-col cols="12" sm="6" md="4">
            <v-card
              class="mx-auto"
              max-width="344"
              to="/speisekartenew"
              style="background-color: #212121;"
            >
              <v-img
                src="../assets/images/drachenmakiganz.jpg"
                height="200px"
              ></v-img>

              <v-card-title
                class="justify-center white--text font-weight-thin"
                style="background-color: #212121;"
              >
                Neue Gerichte
              </v-card-title>
            </v-card>
          </v-col> -->
          <v-col cols="12" sm="6" md="4">
            <v-card class="mx-auto" max-width="344" to="/speisekartenew">
              <v-img
                src="../assets/images/entebowlfront.png"
                height="200px"
              ></v-img>

              <v-card-title
                class="justify-center white--text font-weight-thin"
                style="background-color: #212121;"
              >
                Speisekarte
              </v-card-title>

              <!-- <v-card-subtitle>
              1,000 miles of wonder
            </v-card-subtitle> -->
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-card class="mx-auto" max-width="344" to="/kontakt">
              <v-img
                src="../assets/images/mitnehmenlogo.jpg"
                height="200px"
              ></v-img>

              <v-card-title
                class="justify-center white--text font-weight-thin"
                style="background-color: #212121;"
              >
                Abholung | Bestellung
              </v-card-title>

              <!-- <v-card-subtitle class="text-center">
            </v-card-subtitle> -->
            </v-card>
          </v-col>
          <!-- <v-col cols="12" sm="6" md="4">
            <v-card class="mx-auto" max-width="344" to="/bestseller">
              <v-img
                src="../assets/images/rainbowmakifrontal.jpg"
                height="200px"
              ></v-img>

              <v-card-title
                class="justify-center white--text font-weight-thin"
                style="background-color: #212121;"
              >
                Bestseller
              </v-card-title>
            </v-card>
          </v-col> -->
          <v-col cols="12" sm="6" md="4">
            <v-card class="mx-auto" max-width="344" to="/getraenkekartenew">
              <v-img
                src="../assets/images/getraenkeallgemein.jpg"
                height="200px"
              ></v-img>

              <v-card-title
                class="justify-center white--text font-weight-thin"
                style="background-color: #212121;"
              >
                Getränkekarte
              </v-card-title>

              <!-- <v-card-subtitle>
              1,000 miles of wonder
            </v-card-subtitle> -->
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-card class="mx-auto" max-width="344" to="/restaurant">
              <v-img
                src="../assets/images/restaurantfront.jpg"
                height="200px"
              ></v-img>

              <v-card-title
                class="justify-center white--text font-weight-thin"
                style="background-color: #212121;"
              >
                Restaurant Akaz
              </v-card-title>

              <!-- <v-card-subtitle class="text-center">
            </v-card-subtitle> -->
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-card
              class="mx-auto white--text"
              max-width="344"
              to="/oeffnungszeiten"
              style="background-color:#212121"
            >
              <v-img
                src="../assets/images/akazoeffnungszeiten.png"
                height="200px"
              ></v-img>

              <v-card-title class="justify-center white--text font-weight-thin">
                Öffnungszeiten
              </v-card-title>

              <!-- <v-card-subtitle>
              1,000 miles of wonder
            </v-card-subtitle> -->
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-card class="mx-auto" max-width="344" to="/mittagsmenue">
              <v-img
                src="../assets/images/bentoboxrechts.jpg"
                height="200px"
              ></v-img>

              <v-card-title
                class="justify-center white--text font-weight-thin"
                style="background-color: #212121;"
              >
                Mittagsmenü
              </v-card-title>

              <!-- <v-card-subtitle>
              1,000 miles of wonder
            </v-card-subtitle> -->
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
// import HelloWorld from '../components/HelloWorld'

export default {
  name: 'Home',

  components: {
    // HelloWorld,
  },
  data() {
    return {
      items: [
        {
          src: require('../assets/images/kirinsushifrontview.jpg'),
        },
        {
          src: require('../assets/images/sushitataroverview.jpg'),
        },
        {
          src: require('../assets/images/kirinsushiovermicroview.jpg'),
        },
        {
          src: require('../assets/images/kirinsushimicroview.jpg'),
        },
        {
          src: require('../assets/images/sushitataroverviewwide.jpg'),
        },
        {
          src: require('../assets/images/sushitatarmicrooverviewthunfisch.jpg'),
        },
        {
          src: require('../assets/images/sushitatarmircooverviewlachs.jpg'),
        },
        {
          src: require('../assets/images/sushitatarmicrooverviewgarnelen.jpg'),
        },
        {
          src: require('../assets/images/Golden-CloseUp.jpg'),
        },
        {
          src: require('../assets/images/Golden-Mix-Frontal.jpg'),
        },
        {
          src: require('../assets/images/Golden-schwarzeBackground-ganz.jpg'),
        },
        {
          src: require('../assets/images/entebowlfront.png'),
        },
        {
          src: require('../assets/images/entebowloben.png'),
        },

        {
          src: require('../assets/images/tatargurkenrollseitlich.png'),
        },
        {
          src: require('../assets/images/tatargurkenrollvogel.png'),
        },
        {
          src: require('../assets/images/tatargurkenrollfront.png'),
        },
        {
          src: require('../assets/images/lachskaviarsushifrontal.png'),
        },
        {
          src: require('../assets/images/lachskaviarsushioben.png'),
        },
        {
          src: require('../assets/images/thunfischkaviarsushiflambiertfrontal.png'),
        },
        {
          src: require('../assets/images/thunfischkaviarsushiflambiertseitlich.png'),
        },
        {
          src: require('../assets/images/drachenmakifrontal.jpg'),
        },
        {
          src: require('../assets/images/lachskaviarsushiflambiertoben.png'),
        },
        {
          src: require('../assets/images/lachskaviarsushiflambiertfrontal.png'),
        },
        {
          src: require('../assets/images/sushitortefrontal.png'),
        },
        {
          src: require('../assets/images/sushidonutsoben.png'),
        },
        {
          src: require('../assets/images/sushitorteoben.png'),
        },
        {
          src: require('../assets/images/sushidonutsfrontal.png'),
        },
        {
          src: require('../assets/images/avocadolachskugelartshotfrontal.jpg'),
        },
        {
          src: require('../assets/images/drachenmakivogelperspektive.jpg'),
        },
        {
          src: require('../assets/images/rainbowmakivogelperspektive.jpg'),
        },
        {
          src: require('../assets/images/drachenmakiganz.jpg'),
        },
        {
          src: require('../assets/images/lachsrollenfrontalshow.jpg'),
        },
        {
          src: require('../assets/images/tempuracaliforniafrontalshow.jpg'),
        },
        {
          src: require('../assets/images/gebackenegarnelenhintergrund.jpg'),
        },
        {
          src: require('../assets/images/garnelenbowloben.png'),
        },
        {
          src: require('../assets/images/garnelenbowlfront.png'),
        },
        {
          src: require('../assets/images/huehnerfleischoben.png'),
        },
        {
          src: require('../assets/images/huehnerfleischfront.png'),
        },
        {
          src: require('../assets/images/tofubowloben.png'),
        },
        {
          src: require('../assets/images/tofubowlfront.png'),
        },
        {
          src: require('../assets/images/lachsbowloben.png'),
        },
        {
          src: require('../assets/images/lachsbowlfront.png'),
        },
        {
          src: require('../assets/images/thunfischbowloben.png'),
        },
        {
          src: require('../assets/images/thunfischfront.png'),
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>
