<template>
  <div class="home">
    <v-carousel hide-delimiters cycle>
      <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src">
        <v-row class="fill-height" align="center" justify="center">
          <div class="text-h1 white--text">
            {{ item.title }}
          </div>
        </v-row></v-carousel-item
      >
    </v-carousel>
    <v-card>
      <v-card-title class="display-3">
        Rotwein
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="desserts" :search="search" :items-per-page="-1"
        ><template v-slot:item.rotwein="{ item }">
          <b>{{ item.rotwein }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template></v-data-table
      >
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Rotwein',
  data() {
    return {
      items: [
        {
          src: require('../assets/images/rotweinpuroben.jpg'),
          title: '',
        },
        {
          src: require('../assets/images/rotweinglow.jpg'),
          title: '',
        },
      ],
      search: '',
      headers: [
        { text: 'Rotwein', value: 'rotwein' },
        { text: 'Menge', value: 'menge' },
        { text: 'Preis', value: 'preis' },
      ],
      desserts: [
        {
          rotwein: 'Zweigel',
          menge: '1/8 l',
          preis: '3,00 €',
        },
        {
          rotwein: 'Zweigel',
          menge: '1/4 l',
          preis: '5,00 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
