<template>
  <div class="home">
    <v-carousel hide-delimiters cycle>
      <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src">
        <v-row class="fill-height" align="center" justify="center">
          <div class="text-h1 white--text">
            {{ item.title }}
          </div>
        </v-row></v-carousel-item
      >
    </v-carousel>
    <backtospeisekarte class="mt-4"></backtospeisekarte>
    <div class="d-flex flex-wrap mt-6">
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            to="/vorspeisestandard"
            style="background-color: #212121;"
          >
            <v-img
              src="../assets/images/minifruehlingsrolleohnehintergrund.png"
              height="200px"
            ></v-img>

            <v-card-title class="justify-center font-weight-thin">
              Vorspeise
            </v-card-title>

            <!-- <v-card-subtitle>
              1,000 miles of wonder
            </v-card-subtitle> -->
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            to="/suppen"
            style="background-color: #212121;"
          >
            <v-img
              src="../assets/images/pikantesuppeohnehintergrund.png"
              height="200px"
            ></v-img>

            <v-card-title class="justify-center font-weight-thin">
              Suppen
            </v-card-title>

            <!-- <v-card-subtitle>
              1,000 miles of wonder
            </v-card-subtitle> -->
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            to="/dimsum"
            style="background-color: #212121;"
          >
            <v-img
              src="../assets/images/dimsum-cutted.png"
              height="200px"
            ></v-img>

            <v-card-title class="justify-center font-weight-thin">
              Dim-Sum
            </v-card-title>

            <!-- <v-card-subtitle>
              1,000 miles of wonder
            </v-card-subtitle> -->
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Backtospeisekarte from './Backtospeisekarte.vue';
export default {
  components: { Backtospeisekarte },
  name: 'Vorspeise',
  data() {
    return {
      items: [
        {
          src: require('../assets/images/dimsum-uncutted-background.png'),
          title: '',
        },
        {
          src: require('../assets/images/gebackenegarnelenmithintergrund.jpg'),
          title: '',
        },
        {
          src: require('../assets/images/minifruehlingsrollemithintergrund.jpg'),
          title: '',
        },
        {
          src: require('../assets/images/misosuppemithintergrund.jpg'),
          title: '',
        },
        {
          src: require('../assets/images/pikantesuppemithintergrund.jpg'),
          title: '',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
