<template>
  <div class="home">
      <div class="container">
          <br>
          <p class="display-2 font-weight-thin">Impressum</p>
          <br>
          <p class="ml-6 font-weight-bold">Yi Zhang <br> Geschäftsführer</p>
          <p class="ml-6">Akaz <br> Asiatisches Restaurant <br>Hütteldorferstraße 130D, 1140 Wien, Österreich <br>+43 1 4162667 </p>
          <p class="ml-6">akaz.restaurant@gmail.com</p>
          <p class="ml-6">Eingetragenes Einzelunternehmen</p>
          <p class="ml-6">UID ATU 74672428</p>
          <p class="ml-6">Mitglied der WKÖ</p>
          <p class="ml-6">Gewerbebehörde: Magistratisches Bezirksamt 14. Bezirk und MA 63</p>
          <p class="ml-6">Sämtlich auf unseren Seiten enthaltenen Text, Bilder und Informationen im generellen stellen keine Aufforderung, keine Grundlage
              oder Empfehlung zur Anwendung oder Nichtanwendung jedweger Technologie oder Verfahren. Des Weiteren kann keine Garantie für Richtigkeit,
              Vollständigkeit und Aktualität der Angaben übernommen werden.
              <br>
              Infolgedessen sind die Text, Bilder und Informationen im allgemeinen keine Grundlage für einen Vertrag oder eine Verpflichtung, welcher Art auch immer.
              <br> Es wird im Weiteren keine Haftung für die Folgen, insbesondere Verluste,
              die sich auf Basis der bereitgestellten Texte, Bilder und Informationen im allgemeinen und darin enthaltenen Ansichten und Rückschlüssen
              ergeben bzw. ergeben könnten, übernommen.
              <br>
                Akaz übernimmt keine Verantwortung, Gewährleistung oder Haftung für die Inhalte von externen Websites, die über Links von dieser Website erreicht werden können, da
                die ständige inhaltliche Überprüfung aller Links nicht möglich ist.
          </p>
          <p class="ml-6 font-weight-bold"><u>Leon Zhang</u>       
          <v-btn icon
            href="https://www.instagram.com/leeon.zhg/?hl=de">
            <v-icon
                >mdi-instagram
            </v-icon>
      </v-btn> 
      <br> Webdesign und Konzep</p>
          <p class="ml-6 font-weight-bold"><u>Leon Zhang</u>
                    <v-btn icon
            href="https://www.instagram.com/leeon.zhg/?hl=de">
            <v-icon
                >mdi-instagram
            </v-icon>
      </v-btn> 
           <br> Photography</p>
          <p class="ml-6">Alle Bilder und Logos auf der Website unterliegen dem Urheberrecht. Somit dürfen die Bilder in welcher Art und Weise auch
              immer nicht von eine andere Person außer der Urheber selbst verwendet werden. </p>
          <p class="ml-6 font-weight-bold">Copyright © Akaz</p>

      </div>
  </div>
</template>

<script>
export default {
      methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
