<template>
  <div class="home">
    <v-carousel hide-delimiters cycle>
      <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src">
        <v-row class="fill-height" align="center" justify="center">
          <div class="text-h1 white--text">
            {{ item.title }}
          </div>
        </v-row></v-carousel-item
      >
    </v-carousel>
    <!-- <div class="container">
      <backtospeisekarte></backtospeisekarte>
    </div> -->
    <v-card>
      <v-card-title class="display-3">
        Sashimi
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.speise="{ item }">
          <b>{{ item.speise }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template></v-data-table
      >
    </v-card>
  </div>
</template>

<script>
// import Backtospeisekarte from './Backtospeisekarte.vue';
export default {
  // components: {Backtospeisekarte},
  name: 'Sashimi',
  data() {
    return {
      items: [
        {
          src: require('../assets/images/sashimiseitlich.jpg'),
          title: '',
        },
        {
          src: require('../assets/images/sashimivogelpersektive.jpg'),
          title: '',
        },
        {
          src: require('../assets/images/sashimifrontal.jpg'),
          title: '',
        },
      ],
      search: '',
      headers: [
        {
          text: 'Nummer',
          align: 'start',
          sortable: false,
          value: 'nummer',
        },
        { text: 'Speise', value: 'speise' },
        { text: 'Allergene', value: 'allergene' },
        { text: 'Preis', value: 'preis' },
      ],
      desserts: [
        {
          nummer: 'S1',
          speise: 'Sashimi Set groß',
          allergene: '(D)',
          preis: '23,90 €',
        },
        {
          nummer: 'S2',
          speise: 'Sashimi Set mittel',
          allergene: '(D)',
          preis: '21,90 €',
        },
        {
          nummer: 'S3',
          speise: 'Sashimi Set klein',
          allergene: '(D)',
          preis: '19,90 €',
        },
        {
          nummer: 'S4',
          speise: 'Thunfisch Sashimi',
          allergene: '(D)',
          preis: '22,30 €',
        },
        {
          nummer: 'S5',
          speise: 'Lachs Sashimi',
          allergene: '(D)',
          preis: '18,90 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
