<template>
  <div class="home">
    <v-carousel
      hide-delimiters
      hide-arrows
      :left-control-icon="false"
      :right-control-icon="false"
    >
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        :src="item.src"
        reverse-transition="fade-transition"
        transition="fade-transition"
      ></v-carousel-item>
    </v-carousel>
    <!-- <div class="container">
      <backtohauptspeise></backtohauptspeise>
    </div> -->
    <v-card>
      <v-card-title class="display-3">
        Eierreis
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.speise="{ item }">
          <b>{{ item.speise }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template></v-data-table
      >
    </v-card>
  </div>
</template>

<script>
// import Backtohauptspeise from './Backtohauptspeise.vue';
export default {
  // components: { Backtohauptspeise },
  name: 'Nudeln',
  data() {
    return {
      items: [
        {
          src: require('../assets/images/gebratenerreismithintergrund.jpg'),
        },
      ],
      search: '',
      headers: [
        {
          text: 'Nummer',
          align: 'start',
          sortable: false,
          value: 'nummer',
        },
        { text: 'Speise', value: 'speise' },
        { text: 'Allergene', value: 'allergene' },
        { text: 'Preis', value: 'preis' },
      ],
      desserts: [
        {
          nummer: '181',
          speise: 'Gebratener Eierreis mit Hühnerfleisch und Gemüse',
          allergene: '(A, C, F, N)',
          preis: '9,00 €',
        },
        {
          nummer: '182',
          speise: 'Gebratener Eierreis mit Rindfleisch und Gemüse',
          allergene: '(A, C, F, N)',
          preis: '9,40 €',
        },
        {
          nummer: '183',
          speise:
            'Gebratener Eierreis mit Meeresfrüchte oder Garnelen und Gemüse',
          allergene: '(A, C, D, F, R, N)',
          preis: '10,60 €',
        },
        {
          nummer: '184',
          speise: 'Gebratener Eierreis mit Gemüse',
          allergene: '(A, C, F, N)',
          preis: '8,50 €',
        },
        {
          nummer: '185',
          speise: 'Gebratener Eierreis mit knuspriger Ente und Gemüse',
          allergene: '(A, C, F, N)',
          preis: '12,40 €',
        },
        {
          nummer: '186',
          speise: 'Gebratener Eierreis mit knusprigem Huhn und Gemüse',
          allergene: '(A, C, F, G, N)',
          preis: '11,70 €',
        },
        {
          nummer: '187',
          speise: 'Gebratener Eierreis mit Tofu und Gemüse',
          allergene: '(A, C, F, G, N)',
          preis: '10,00 €',
        },
        {
          nummer: '188',
          speise: 'Gebratener Eierreis mit Lachssteak und Gemüse',
          allergene: '(A, C, D, F, G, N)',
          preis: '12,80 €',
        },
        // {
        //   nummer: '189',
        //   speise: 'Gebratener Eierreis mit faschiertem Fleisch und Gemüse',
        //   allergene: '(A, C, D, F, G, N)',
        //   preis: '10,10 €',
        // },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
